import { moduleNamespace, routeNamespace } from '@Store/config.store';

export const addNamespace = (action: string) => moduleNamespace + '/' + action;

export const addRouteNamespace = (route: string) =>
	routeNamespace + '/' + route;

export const addParam = (param: { name: string; value: string }) =>
	routeNamespace + '/?' + param.name + '=' + param.value;

export const addExternalNamespace = (namespace: string, route: string) =>
	namespace + '/' + route;
