import { BadRequest, Conflict } from 'http-errors';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { CompanySettings } from '@innovyze/stylovyze';
import superadmin from '../apis/superadmin.api';

const baseEndpoint = '/settings';

export const getCompanySettings = (organizationId: string) =>
	superadmin.get(`${baseEndpoint}/${organizationId}`);

const validateCompanySettingsResponse = (
	result: AxiosResponse<any>,
): AxiosResponse<any> => {
	if (
		result.data.errorMessage ===
		'[409] Organization team mapping already exists.'
	) {
		throw new Conflict('Organization team mapping already exists');
	}
	return result;
};

const validateCompanySettingsError = (error: any) => {
	if (error.message === 'Request failed with status code 400') {
		throw new BadRequest('Invalid request');
	}
	throw error;
};

export const createCompanySettings = (params: CompanySettings) =>
	superadmin
		.put(baseEndpoint, params)
		.then(result => {
			return validateCompanySettingsResponse(result);
		})
		.catch(error => {
			return validateCompanySettingsError(error);
		});

export const updateCompanySettings = (params: Partial<CompanySettings>) => {
	const { organization, ...orgParams } = params;
	return superadmin
		.patch(`${baseEndpoint}/${organization}`, orgParams)
		.then(result => {
			return validateCompanySettingsResponse(result);
		})
		.catch(error => {
			return validateCompanySettingsError(error);
		});
};
