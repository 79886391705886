export enum Environments {
	local = 'local',
	dev = 'development',
	qa = 'qa',
	prod = 'production',
}

export const getEnvironment = (): Environments => {
	const host = location.host.split('.');

	const localReg = /^localhost/g;
	const developmentReg = /-dev$/g;
	const qaReg = /-qa$/g;

	switch (true) {
		case localReg.test(host[0]):
			return Environments.local;
		case developmentReg.test(host[1]):
			return Environments.dev;
		case qaReg.test(host[1]):
			return Environments.qa;
		default:
			return Environments.prod;
	}
};

export const getApiEnvironment = (apiEnv: string): Environments => {
	switch (apiEnv) {
		case 'prod':
			return Environments.prod;
		case 'qa':
			return Environments.qa;
		case 'dev':
			return Environments.dev;
		default:
			return Environments.local;
	}
};
