import { GeocoderResultWithMapBounds, OrgAccount } from '@Types/account.types';

import { FormikProps } from 'formik';
import { GeocoderResult } from '@innovyze/inno-map';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';

export default function useUpdateGeocoderData(
	formikProps: FormikProps<OrgAccount>,
) {
	const { setAccountFormData } = useAccountFormContext();
	const { setFieldValue, setFieldTouched } = formikProps;
	const updateGeocoderData = (newGeocoderData: GeocoderResult | null) => {
		const newGeocoderMapBounds =
			newGeocoderData as GeocoderResultWithMapBounds;
		setAccountFormData(formData => ({
			...formData,
			geocoderData: newGeocoderData || undefined,
		}));
		setFieldValue('location', {
			address: newGeocoderData?.place_name,
			mapPosition: {
				center: newGeocoderData?.center,
				zoom: newGeocoderData?.relevance,
			},
			mapBounds: newGeocoderMapBounds?.bbox,
		});
		setFieldTouched('location.address', true);
	};

	return { updateGeocoderData };
}
