import { DEBOUNCE_TIME } from '@innovyze/stylovyze';
import { OrgAccount } from '@Types/account.types';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { entitlementSchema } from '../../../schemas/entitlement';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useState } from 'react';

interface AccountDetailsState {
	accountDetails: OrgAccount | undefined;
}

export default function useDebouncedEntitlementValidation() {
	const [isDebouncing, setIsDebouncing] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const setValidatedEntitlement = (entitlement: SubscriptionConfig) => {
		entitlementSchema
			.validate(entitlement)
			.then(() => {
				if (entitlement.product) {
					setIsValid(true);
				} else {
					setIsValid(false);
				}
				setIsDebouncing(false);
			})
			.catch(() => {
				setIsValid(false);
			});
	};

	const handleDebounceChange = useDebouncedCallback(
		(entitlement: SubscriptionConfig) => {
			setValidatedEntitlement(entitlement);
			setIsDebouncing(false);
		},
		DEBOUNCE_TIME / 4,
	);

	const debounceEntitlementValidation = (entitlement: SubscriptionConfig) => {
		setIsDebouncing(true);
		handleDebounceChange.callback(entitlement);
	};
	return { isDebouncing, debounceEntitlementValidation, isValid };
}
