import { GeocoderResult } from '@innovyze/inno-map';
import { initialAccountFormData, OrgAccount } from '@Types/account.types';
import React from 'react';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { OrgLibrariesConfig } from '@Types/libraries.types';

export interface AccountFormContextType {
	accountFormData: AccountFormDataType;
	setAccountFormData: React.Dispatch<
		React.SetStateAction<AccountFormDataType>
	>;
	isAddingEntitlements?: boolean;
	setIsAddingEntitlements: React.Dispatch<React.SetStateAction<boolean>>;
	loadEntitlement?: SubscriptionConfig;
	setLoadEntitlement: React.Dispatch<
		React.SetStateAction<SubscriptionConfig | undefined>
	>;
}

export interface AccountFormDataType {
	accountDetails: OrgAccount;
	geocoderData?: GeocoderResult;
	entitlements: SubscriptionConfig[];
	librariesDetails: OrgLibrariesConfig;
}

export const AccountFormContext = React.createContext<AccountFormContextType>({
	accountFormData: initialAccountFormData,
	setAccountFormData: () => {
		return;
	},
	isAddingEntitlements: false,
	setIsAddingEntitlements: () => {
		return;
	},
	loadEntitlement: undefined,
	setLoadEntitlement: () => {
		return;
	},
});

export const useAccountFormContext = () => React.useContext(AccountFormContext);
