import { CustomInfoCard } from '@Pages/UpsertAccount/UpsertAccount.styles';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';
import { Button } from '@innovyze/stylovyze';
import React from 'react';
import { useTriggerInspectionTilingForTenant } from './hooks/useStartTiling';

const TileSingleTenantForm = () => {
	const { accountFormData } = useAccountFormContext();
	const triggerInspectionTilingForTenant =
		useTriggerInspectionTilingForTenant(accountFormData);

	return (
		<CustomInfoCard title="Tiling" dataCy="tile-single-tenant-card">
			<Button
				variant="contained"
				dataCy="trigger-inspection-tiling"
				onClick={triggerInspectionTilingForTenant}>
				Start inspection tiling
			</Button>
		</CustomInfoCard>
	);
};

export default TileSingleTenantForm;
