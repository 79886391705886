import {
	MenuItem,
	StylovyzeTable,
	rem,
	getEnvironment,
} from '@innovyze/stylovyze';
import React, { useEffect, useState } from 'react';
import { clearSingleAccount, getAccounts } from '@Actions/accounts';
import { useDispatch, useSelector } from 'react-redux';

import Moment from '@date-io/moment';
import { SUBSCRIPTIONS_LIST } from '@Types/entitlement.types';
import { addRouteNamespace } from '@Utils/actions';
import { clearSingleAccountEntitlements } from '@Actions/entitlements';
import { getValue } from '@Selectors';
import { useHistory } from 'react-router';
import { AccountTypes } from '@innovyze/shared-utils/lib/types/Account';

const moment = new Moment();

const ROWS_PER_PAGE = [5, 10, 15, 25, 50, 100];

const headers: {
	key: string;
	cell: React.ReactNode;
	width?: string;
	align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
	sortable?: boolean;
	maxWidth?: string;
	dataCy?: string;
}[] = [
	{
		cell: 'Name',
		dataCy: 'col-name',
		key: 'name',
		align: 'left',
		width: `${rem(450)}`,
		maxWidth: `${rem(450)}`,
	},
	{
		cell: 'Team ID',
		dataCy: 'col-teamid',
		key: 'team-id',
		align: 'left',
		width: `${rem(250)}`,
		maxWidth: `${rem(350)}`,
	},
	{
		cell: 'Organization ID',
		dataCy: 'col-organizationid',
		key: 'organization-id',
		align: 'left',
		width: `${rem(250)}`,
		maxWidth: `${rem(350)}`,
	},
	{
		cell: 'Account Type',
		dataCy: 'col-accountType',
		key: 'account-type',
		align: 'left',
		width: `${rem(250)}`,
		maxWidth: `${rem(350)}`,
	},
	{
		cell: 'Last Updated',
		dataCy: 'col-last-updated',
		key: 'last-updated',
		align: 'left',
		width: `${rem(150)}`,
		maxWidth: `${rem(150)}`,
	},
	{
		cell: 'Subscriptions',
		dataCy: 'col-subscriptions',
		key: 'subscriptions',
		align: 'left',
		width: 'auto',
		maxWidth: `${rem(450)}`,
	},
];

const AccountsTable = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [selectedLimitIndex, setSelectedLimitIndex] = useState(1);
	const [search, setSearch] = useState('');

	const {
		accounts: { initialized, paginatedAccounts },
	} = useSelector(getValue);

	const rows = paginatedAccounts?.orgAccounts.length
		? paginatedAccounts?.orgAccounts.map(account => {
				const {
					organizationId = '',
					organizationName = '',
					lastModifiedDate = '',
					teamId = '',
					accountType = getEnvironment() == 'PROD'
						? AccountTypes.PRODUCTION
						: AccountTypes.TEST,
				} = account || {};
				const lastUpdated = moment.moment(lastModifiedDate).fromNow();
				const entitlements =
					account?.entitlements
						?.map(
							entitlement =>
								SUBSCRIPTIONS_LIST.find(
									el => el.key == entitlement,
								)?.value,
						)
						.join(', ') || '--';
				return {
					key: organizationId,
					cells: initialized
						? [
								organizationName,
								teamId || '--',
								organizationId,
								accountType,
								lastUpdated,
								entitlements,
						  ]
						: [],
					pathToGoWhenClicked: addRouteNamespace(
						`edit-account/${organizationId}`,
					),
				};
		  })
		: [];

	const menuItems: MenuItem[] = [
		{
			onClick: () => {
				dispatch(clearSingleAccount());
				dispatch(clearSingleAccountEntitlements());
				history.push(addRouteNamespace('create-account'));
			},
			text: 'Create Account',
			cy: 'create-account-button',
		},
	];

	useEffect(() => {
		dispatch(
			getAccounts({
				limit: ROWS_PER_PAGE[selectedLimitIndex],
				offset: 0,
				q: {
					organizationName: search,
					teamId: search,
					organizationId: search,
				},
			}),
		);
	}, []);

	return (
		<StylovyzeTable
			buttonStyle={{
				color: 'primary',
				variant: 'contained',
			}}
			minHeight={rem(302)}
			dataCy="accounts-table"
			search={{
				onChange: query => {
					setSearch(query);
				},
				onRequestSearch: () => {
					dispatch(
						getAccounts({
							limit: ROWS_PER_PAGE[selectedLimitIndex],
							offset: 0,
							q: {
								organizationName: search,
								teamId: search,
								organizationId: search,
							},
						}),
					);
				},
				onCancelSearch: () => {
					setSearch('');
					dispatch(
						getAccounts({
							limit: ROWS_PER_PAGE[selectedLimitIndex],
							offset: 0,
							q: {
								organizationName: '',
								teamId: '',
								organizationId: '',
							},
						}),
					);
				},
				placeholder: 'Search',
				dataCy: 'search-01',
			}}
			rowsPerPage={{
				options: ROWS_PER_PAGE.map((name, index) => ({
					name,
					onClick: () => {
						setSelectedLimitIndex(index);
						dispatch(
							getAccounts({
								limit: ROWS_PER_PAGE[index],
								offset: 0,
								q: {
									organizationName: search,
									teamId: search,
									organizationId: search,
								},
							}),
						);
					},
				})),
				selectedIndex: selectedLimitIndex,
			}}
			pagination={{
				totalNumberOfPages: paginatedAccounts?.pagination.pages || 0,
				currentPage: paginatedAccounts?.pagination.current || 1,
				dataCy: 'pagination-01',
				onChange: (_e, page: number) => {
					dispatch(
						getAccounts({
							limit: ROWS_PER_PAGE[selectedLimitIndex],
							offset: page,
							q: {
								organizationName: search,
								teamId: search,
								organizationId: search,
							},
						}),
					);
				},
			}}
			headers={headers}
			menuItems={menuItems}
			rows={rows}
			initialized={initialized}
			useSkeleton={{
				rowCount: 10,
			}}
		/>
	);
};

export default AccountsTable;
