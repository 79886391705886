import React, { useEffect } from 'react';
import {
	SUBSCRIPTIONS_LIST,
	SubscriptionConfig,
} from '@Types/entitlement.types';

import { FormikProps } from 'formik';
import { initialEntitlement } from '@Components';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';
import useDebouncedEntitlementValidation from '@Components/EntitlementsTable/hooks/useDebouncedEntitlementValidation';

interface Attrs {
	formikProps: FormikProps<SubscriptionConfig>;
	setIsEntitlementEditing: (value: React.SetStateAction<boolean>) => void;
	setDisabledButton: (value: React.SetStateAction<boolean>) => void;
	isEntitlementEditing: boolean;
}

export default function useAccountEntitlementsLogic({
	formikProps,
	setIsEntitlementEditing,
	isEntitlementEditing,
	setDisabledButton,
}: Attrs) {
	const { values, setFieldValue, setValues } = formikProps;
	const {
		isAddingEntitlements,
		setIsAddingEntitlements,
		setAccountFormData,
		loadEntitlement,
		setLoadEntitlement,
	} = useAccountFormContext();

	const clearEntitlements = () => {
		setValues(initialEntitlement);
	};

	useEffect(() => {
		if (typeof values.product === 'object') {
			setFieldValue('product', '');
		}
	}, [values.product]);

	useEffect(() => {
		if (loadEntitlement) {
			setIsEntitlementEditing(true);
			setFieldValue(
				'product',
				SUBSCRIPTIONS_LIST.find(
					el => el.key === loadEntitlement.product,
				),
			);
			setValues(loadEntitlement);
			setLoadEntitlement(undefined);
		}
	}, [loadEntitlement]);

	useEffect(() => {
		if (isAddingEntitlements || (isEntitlementEditing && values.product)) {
			setAccountFormData(_accountFormData => {
				if (isEntitlementEditing) {
					return {
						..._accountFormData,
						entitlements: _accountFormData.entitlements.map(
							entitlement =>
								entitlement.product === values.product
									? values
									: entitlement,
						),
					};
				} else {
					return {
						..._accountFormData,
						entitlements: [
							..._accountFormData?.entitlements,
							values,
						],
					};
				}
			});
			clearEntitlements();
			setIsAddingEntitlements(false);
			setLoadEntitlement(undefined);
			setIsEntitlementEditing(false);
		}
	}, [isAddingEntitlements]);

	const { debounceEntitlementValidation, isValid } =
		useDebouncedEntitlementValidation();

	useEffect(() => {
		debounceEntitlementValidation(values);
	}, [values]);

	useEffect(() => {
		if (isValid) {
			setDisabledButton(false);
		} else {
			setDisabledButton(true);
		}
	}, [isValid]);

	return { clearEntitlements };
}
