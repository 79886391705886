import { MapModes, ReactMap } from '@innovyze/inno-map';

import { FormikProps } from 'formik';
import { OrgAccount } from '@Types/account.types';
import React from 'react';
import { StyledMap } from './AccountLocationMap.styles';
import irvineOffice from '../../store/irvineOffice.json';
import useMapBounds from './hooks/useMapBounds';
import { useSiteConfig } from '@innovyze/stylovyze';

interface Props {
	formikProps: FormikProps<OrgAccount>;
}

const AccountLocationMap = ({ formikProps }: Props) => {
	const { config } = useSiteConfig();
	const { updateMapBounds } = useMapBounds(formikProps);
	const handleBoundsChanged = (bounds: number[]) => {
		updateMapBounds(bounds);
	};
	return (
		<StyledMap>
			<ReactMap
				mapKey={config?.mapbox.key || ''}
				mode={MapModes.Navigation}
				dataServices={[]}
				geocoderId="location.address"
				autoResize
				position={irvineOffice}
				onBoundsChange={handleBoundsChanged}
			/>
		</StyledMap>
	);
};

export default AccountLocationMap;
