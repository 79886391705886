import { StyledSuperAdminPageWrapper } from '@Pages/SuperAdmin/SuperAdmin.styles';
import { CustomInfoCard } from '@Pages/UpsertAccount/UpsertAccount.styles';
import { Button, SecondaryNavItem } from '@innovyze/stylovyze';
import React from 'react';
import { useTriggerInspectionTilingForAllTenants } from './hooks/useTiling';
import { useIsDevToolsEnabled } from '@Utils/featureFlags';

function TilingTab() {
	const handleTriggerInspectionTilingForAllTenants =
		useTriggerInspectionTilingForAllTenants();

	return (
		<CustomInfoCard
			title="Inspection Tiling"
			dataCy="tile-single-tenant-card">
			<Button
				variant="contained"
				dataCy="trigger-inspection-tiling"
				onClick={handleTriggerInspectionTilingForAllTenants}>
				Tile all tenants
			</Button>
		</CustomInfoCard>
	);
}

export default function DevTools() {
	const isDevToolsEnabled = useIsDevToolsEnabled();

	const tabs: SecondaryNavItem[] = [];

	if (!isDevToolsEnabled) {
		tabs.push({
			content: <h1>Feature not enabled</h1>,
			title: 'Disabled',
		});
	} else {
		tabs.push({
			content: TilingTab(),
			title: 'Tiling',
		});
	}

	return (
		<StyledSuperAdminPageWrapper
			title={'Dev Tools'}
			applyPadding={true}
			isLoading={false}
			secondaryNav={{
				items: tabs,
				itemIndex: 0,
			}}></StyledSuperAdminPageWrapper>
	);
}
