import { FORM_MODES, StylovyzeForm } from '@innovyze/stylovyze';
import React, { useState } from 'react';
import {
	SUBSCRIPTIONS_LIST,
	SubscriptionConfig,
} from '@Types/entitlement.types';

import AccountEntitlementsFields from './AccountEntitlementsFields';
import { CustomInfoCard } from '@Pages/UpsertAccount/UpsertAccount.styles';
import { accountDetailsSchema } from '../../schemas/account';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';

export const initialEntitlement = {} as SubscriptionConfig;

const AccountEntitlementsForm = () => {
	const [disabledButton, setDisabledButton] = useState(false);
	const [isEntitlementEditing, setIsEntitlementEditing] = useState(false);
	const { setIsAddingEntitlements } = useAccountFormContext();
	return (
		<CustomInfoCard
			title="Subscriptions"
			fullHeight
			dataCy="subscriptionsForm"
			menuItems={[
				{
					text: isEntitlementEditing
						? 'Save Subscription'
						: 'Add Subscription',
					onClick: () => {
						setIsAddingEntitlements(true);
					},
					props: { disabled: disabledButton },
					cy: isEntitlementEditing
						? 'save-subscription'
						: 'add-subscription',
				},
			]}
			buttonStyle={{
				variant: 'outlined',
				color: 'primary',
			}}>
			<StylovyzeForm
				initialValues={initialEntitlement}
				mode={FORM_MODES.EDIT}
				validationSchema={accountDetailsSchema}
				onSubmit={() => {
					return;
				}}>
				{formikProps => (
					<AccountEntitlementsFields
						formikProps={formikProps}
						setIsEntitlementEditing={setIsEntitlementEditing}
						subscriptionsList={SUBSCRIPTIONS_LIST}
						isEntitlementEditing={isEntitlementEditing}
						setDisabledButton={setDisabledButton}
					/>
				)}
			</StylovyzeForm>
		</CustomInfoCard>
	);
};

export default AccountEntitlementsForm;
