import {
	ContentWrapper,
	EntitlementsInfoCard,
} from './EntitlementsCard.styles';

import EntitlementsTable from './EntitlementsTable';
import EntitlementsTablePlaceholder from './EntitlementsTablePlaceholder';
import React from 'react';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';

export const EntitlementsCard = () => {
	const { accountFormData, setAccountFormData } = useAccountFormContext();

	const setEntitlements = (entitlements: SubscriptionConfig[]) =>
		setAccountFormData(_accountFormData => ({
			..._accountFormData,
			entitlements,
		}));

	return (
		<>
			{accountFormData?.entitlements.filter(e => !e?.deleted).length ? (
				<EntitlementsTable
					entitlements={accountFormData?.entitlements || []}
					setEntitlements={setEntitlements}
				/>
			) : (
				<EntitlementsInfoCard
					title="Subscriptions"
					fullHeight
					dataCy="emptyEntitlements">
					<ContentWrapper>
						<EntitlementsTablePlaceholder />
					</ContentWrapper>
				</EntitlementsInfoCard>
			)}
		</>
	);
};

export default EntitlementsCard;
