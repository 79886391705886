import * as yup from 'yup';

const entitlementShape = {
	product: yup.string().required('Entitlement name is required'),
	channelLimit: yup.number(),
	numberOfEntitlements: yup.number().notRequired(),
	numberOfEntitlementsUsed: yup.number(),
	expiry: yup.string().required('Should have an expiration date'),
	startDate: yup.string().required('Should have an start date'),
	deleted: yup.boolean(),
};

export const entitlementSchema = yup.object(entitlementShape);
