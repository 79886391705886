import * as accountsActions from '@Actions/accounts';
import * as entitlementsActions from '@Actions/entitlements';
import * as usersActions from '@Actions/users';

import { BadRequest, Conflict } from 'http-errors';
import {
	CompanySettings,
	InnovyzeRole,
	error as notificationError,
} from '@innovyze/stylovyze';
import { OrgAccount, PaginatedOrgAccounts } from '@Types/account.types';
import { all, put, retry, take, takeLatest } from 'redux-saga/effects';
import {
	getAccount,
	getAccountsService,
	updateAccountDetails,
} from '@Services/accounts';
import {
	getCompanySettings,
	updateCompanySettings,
} from '@Services/companySettings';

import { AccountTableSearch } from '@Types/search.types';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

function* getAccountsSagas(action: PayloadAction<AccountTableSearch>) {
	const response: AxiosResponse<PaginatedOrgAccounts> = yield retry(
		3,
		6000,
		getAccountsService,
		action.payload.limit,
		action.payload.offset,
		action.payload.q,
		action.payload.isAutodeskManagedLibrary,
	);
	try {
		if (response.data) {
			yield put(accountsActions.getAccountsResolved(response.data));
		} else {
			yield put(accountsActions.getAccountsRejected());
			yield put(notificationError('Error getting Account Details'));
		}
	} catch (e) {
		yield put(accountsActions.getAccountsRejected());
		yield put(notificationError('Error getting Account Details'));
	}
}

function* getSingleAccountSaga(action: PayloadAction<string>) {
	const id = action.payload;
	try {
		const [accountResponse, settingResponse]: [
			AxiosResponse<OrgAccount>,
			AxiosResponse<CompanySettings>,
		] = yield all([
			retry(3, 6000, getAccount, id),
			retry(3, 6000, getCompanySettings, id),
		]);

		if (accountResponse.data && settingResponse.data) {
			yield put(
				accountsActions.getSingleAccountResolved({
					...accountResponse.data,
					location: {
						...accountResponse.data.location,
						mapBounds: accountResponse.data.location?.mapBounds?.[0]
							? accountResponse.data.location.mapBounds
							: settingResponse.data
									.defaultNetworkBoundarySWNELngLat,
					},
					teamId: settingResponse.data.teamId,
					domain:
						settingResponse.data.domain ??
						accountResponse.data.domain,
				}),
			);
		} else {
			throw new Error('OperationStatus != SUCCESS');
		}
	} catch (e) {
		yield put(accountsActions.getSingleAccountRejected());
		yield put(notificationError('Error getting Account'));
	}
}

function* updateAccountSagas(action: PayloadAction<string>) {
	yield all([
		take(accountsActions.updateAccountSingleUserInfoResolved),
		take(accountsActions.createAccountDetailsResolved),
		take(entitlementsActions.upsertEntitlementResolved),
		take(entitlementsActions.deleteEntitlementsResolved),
		take(usersActions.bulkInviteUsersResolved),
	]);
	yield put(accountsActions.getAccountAfterUpdateResolved());
	yield put(accountsActions.getSingleAccount(action.payload));
	yield put(
		usersActions.getUsers({
			organization: action.payload,
			limit: 100,
			offset: 1,
			role: InnovyzeRole.ADMIN,
			sort: 'email:1',
		}),
	);
}

function* updateAccountDetailsSagas(action: PayloadAction<OrgAccount>) {
	try {
		const {
			organizationId,
			organizationName,
			location,
			entitlements,
			primaryAdmins,
			teamId,
			domain,
			accountType,
			isAutodeskManagedLibrary,
			allowedLibrariesBySuperAdmin,
		} = action.payload;

		const newAccountDetails: OrgAccount = {
			organizationName,
			primaryAdmins: primaryAdmins.map(admin => ({ email: admin.email })),
			additionalAdmins: [],
			location,
			entitlements,
			organizationId,
			domain,
			accountType,
			isAutodeskManagedLibrary,
			allowedLibrariesBySuperAdmin,
		};

		const newCompanySettings: Partial<CompanySettings> = {
			organizationName,
			organization: organizationId,
			teamId: teamId,
			defaultNetworkBoundarySWNELngLat: location.mapBounds || [
				0, 0, 0, 0,
			],
			domain,
		};

		const accountDetailsResponse: AxiosResponse<OrgAccount> = yield retry(
			3,
			6000,
			updateAccountDetails,
			newAccountDetails,
		);

		const companySettingsResponse: AxiosResponse<{
			errorMessage?: string;
			operationStatus?: string;
		}> = yield retry(3, 6000, updateCompanySettings, newCompanySettings);
		if (accountDetailsResponse.data && companySettingsResponse.data) {
			yield put(accountsActions.createAccountDetailsResolved());
		}
	} catch (error) {
		yield put(accountsActions.createAccountDetailsRejected());
		if (error instanceof Conflict) {
			yield put(
				notificationError(
					'Error: Team Id exists for another organization',
				),
			);
		} else if (error instanceof BadRequest) {
			yield put(notificationError('Error: invalid Team Id'));
		} else {
			yield put(notificationError('Error Updating Account Details'));
		}
	}
}

function* watchGetAccounts() {
	yield takeLatest(accountsActions.getAccounts, getAccountsSagas);
}
function* watchGetSingleAccount() {
	yield takeLatest(accountsActions.getSingleAccount, getSingleAccountSaga);
}

function* watchUpdateAccountDetails() {
	yield takeLatest(
		accountsActions.updateAccountDetails,
		updateAccountDetailsSagas,
	);
}

function* getAccountAfterUpdate() {
	yield takeLatest(accountsActions.getAccountAfterUpdate, updateAccountSagas);
}

export default [
	watchGetAccounts(),
	watchGetSingleAccount(),
	watchUpdateAccountDetails(),
	getAccountAfterUpdate(),
];
