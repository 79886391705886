import { SubscriptionConfig } from '@Types/entitlement.types';

export default function filterEntitlementsList(
	initialSubscriptionsList: {
		key: string;
		value: string;
	}[],
	usedSubscriptions: SubscriptionConfig[],
	isEditing = false,
) {
	if (isEditing) return initialSubscriptionsList;
	const usedEntitlementsList = usedSubscriptions.map(
		subscription => !subscription?.deleted && subscription.product,
	);
	return initialSubscriptionsList.filter(
		entitlementsKeyPair =>
			!usedEntitlementsList.includes(entitlementsKeyPair.key),
	);
}
