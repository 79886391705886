import * as accountsActions from '@Actions/accounts';
import * as usersActions from '@Actions/users';

import {
	GetUsersParams,
	InviteUserRequest,
	PaginatedUsers,
	RemoveUserRequest,
} from '@Types/user.types';
import { InnovyzeRole, error as notificationError } from '@innovyze/stylovyze';
import {
	bulkUsersActionService,
	getUsers as getUsersService,
} from '@Services/users';
import { put, retry, take, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { OrgAccount } from '@Types/account.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from '@innovyze/shared-utils';
import { applicationNameEntitlementMap } from '@Pages/UpsertAccount/utils/getApplicationFromEntitlement';
import { updateAccountSingleUserInfo } from '@Services/accounts';

export function* updateAccountUsersInfoSagas(
	action: PayloadAction<OrgAccount>,
) {
	try {
		if (action.payload) {
			const { entitlements, organizationId } = action.payload;
			const applicationName =
				entitlements && entitlements[0]
					? applicationNameEntitlementMap.get(
							entitlements[0] as Subscription,
					  )
					: undefined;
			for (const primaryAdmin of action.payload.primaryAdmins) {
				yield put(
					accountsActions.updateAccountSingleUserInfo({
						entitlements: entitlements || [],
						user: primaryAdmin,
						organization: organizationId as string,
						role: InnovyzeRole.ADMIN,
						applicationName,
					}),
				);
			}
			yield put(accountsActions.updateAccountUsersInfoResolved());
		} else {
			yield put(accountsActions.updateAccountUsersInfoRejected());
		}
	} catch (error) {
		yield put(accountsActions.updateAccountUsersInfoRejected());
	}
}

function* updateAccountSingleUserInfoSaga(
	action: PayloadAction<InviteUserRequest>,
) {
	const { entitlements, organization, user, role, applicationName } =
		action.payload;
	try {
		if (!user || !user.email) {
			yield put(accountsActions.updateAccountSingleUserInfoResolved());
			return;
		}
		const userRequest = {
			entitlements,
			organization,
			role,
			email: user.email,
			name: user.name || null,
			applicationName,
		};
		const response: AxiosResponse<OrgAccount> = yield retry(
			3,
			6000,
			updateAccountSingleUserInfo,
			userRequest,
		);
		if (response.data) {
			yield put(accountsActions.updateAccountSingleUserInfoResolved());
		} else if (response.status > 300) {
			yield put(accountsActions.updateAccountSingleUserInfoRejected());
			yield put(
				notificationError('Error Updating primary admin account name'),
			);
		}
	} catch (error) {
		yield put(accountsActions.updateAccountSingleUserInfoRejected());
		yield put(
			notificationError(
				// eslint-disable-next-line quotes
				`If having a primary email address, the name shouldn't be empty`,
			),
		);
	}
}

function* bulkInviteUsersSaga(action: PayloadAction<InviteUserRequest[]>) {
	const operations = [];
	for (const request of action.payload) {
		const singleUserRequest = {
			operation: 'PUT',
			data: {
				email: request.user.email,
				role: request.role,
				organization: request.organization,
				entitlements: request.entitlements,
				applicationName: request.applicationName,
			},
		};
		operations.push(singleUserRequest);
	}
	if (operations.length === 0) {
		yield put(usersActions.bulkInviteUsersResolved());
		return;
	}
	const bulkUserRequest = { operations };
	try {
		const response: AxiosResponse<OrgAccount> = yield retry(
			3,
			6000,
			bulkUsersActionService,
			bulkUserRequest,
		);
		if (response.data) {
			yield put(usersActions.bulkInviteUsersResolved());
		} else {
			yield put(usersActions.bulkInviteUsersRejected());
			yield put(notificationError('Error inviting additional admins'));
		}
	} catch (error) {
		yield put(usersActions.bulkInviteUsersRejected());
		yield put(notificationError('Error inviting additional admins'));
	}
}

function* bulkRemoveUsersSaga(action: PayloadAction<RemoveUserRequest[]>) {
	const operations = [];
	for (const request of action.payload) {
		const singleUserRequest = {
			operation: 'REMOVE',
			data: request,
		};
		operations.push(singleUserRequest);
	}
	if (operations.length === 0) {
		yield put(usersActions.bulkRemoveUsersResolved());
		return;
	}
	const bulkUserRequest = { operations };
	try {
		const response: AxiosResponse<OrgAccount> = yield retry(
			3,
			6000,
			bulkUsersActionService,
			bulkUserRequest,
		);
		if (response.data) {
			yield put(usersActions.bulkRemoveUsersResolved());
		} else {
			yield put(usersActions.bulkRemoveUsersRejected());
			yield put(notificationError('Error removing additional admins'));
		}
	} catch (error) {
		yield put(usersActions.bulkRemoveUsersRejected());
		yield put(notificationError('Error removing additional admins'));
	}
}

export function* getUsersSaga(action: PayloadAction<GetUsersParams>) {
	try {
		if (action.payload) {
			const singleAccount: PayloadAction<OrgAccount> = yield take(
				accountsActions.getSingleAccountResolved,
			);
			const getUsersResponse: AxiosResponse<PaginatedUsers> = yield retry(
				3,
				6000,
				getUsersService,
				action.payload,
			);
			if (singleAccount.payload?.primaryAdmins?.length > 0) {
				for (let i = 0; i < getUsersResponse.data.users.length; i++) {
					if (
						getUsersResponse.data.users[i].email ===
						singleAccount.payload?.primaryAdmins[0].email
					) {
						getUsersResponse.data.users.splice(i, 1);
						break;
					}
				}
			}
			yield put(usersActions.getUsersResolved(getUsersResponse.data));
		} else {
			yield put(usersActions.getUsersRejected());
		}
	} catch (error) {
		yield put(usersActions.getUsersRejected());
	}
}

function* watchUpdateAccountSingleUserInfo() {
	yield takeLatest(
		accountsActions.updateAccountSingleUserInfo,
		updateAccountSingleUserInfoSaga,
	);
}

function* watchUpdateAccountUsersInfo() {
	yield takeLatest(
		accountsActions.updateAccountUsersInfo,
		updateAccountUsersInfoSagas,
	);
}

function* watchGetUsers() {
	yield takeLatest(usersActions.getUsers, getUsersSaga);
}

function* watchBulkInviteUsers() {
	yield takeLatest(usersActions.bulkInviteUsers, bulkInviteUsersSaga);
}

function* watchBulkRemoveUsers() {
	yield takeLatest(usersActions.bulkRemoveUsers, bulkRemoveUsersSaga);
}

export default [
	watchUpdateAccountSingleUserInfo(),
	watchUpdateAccountUsersInfo(),
	watchGetUsers(),
	watchBulkInviteUsers(),
	watchBulkRemoveUsers(),
];
