import { AxiosResponse } from 'axios';
import { SubscriptionConfig } from '@Types/entitlement.types';
import superadmin from '../apis/superadmin.api';

export const upsertSubscriptions = (
	subscriptions: SubscriptionConfig[],
): Promise<AxiosResponse<SubscriptionConfig>> => {
	return superadmin.put('/subscription', { subscriptions });
};

export const deleteSubscription = (
	subscription: SubscriptionConfig,
): Promise<AxiosResponse<SubscriptionConfig>> => {
	return superadmin.delete(
		`/subscription?subscription=${subscription._id}&organization=${subscription.organization}&product=${subscription.product}`,
	);
};

export const getSingleAccountSubscriptions = (
	id: string,
): Promise<AxiosResponse<SubscriptionConfig>> => {
	return superadmin.get(`/subscriptions?organization=${id}&limit=10`, {});
};
