import * as yup from 'yup';

const emailShape = yup
	.string()
	.email('Must be a valid email (account@domain.com)')
	.nullable();

const nameShape = yup
	.string()
	.min(3, 'Must be at least 3 characters')
	.transform(value => (value === '' ? null : value))
	.trim()
	.nullable();

const primaryAdminShape = yup.object({
	email: emailShape,
	name: nameShape,
});

const locationShape = {
	address: yup.string().required(''),
	mapPosition: yup.object().shape({
		center: yup.array().of(yup.number()),
		address: yup.string(),
	}),
};

const accountDetailsShape = {
	organizationName: yup
		.string()
		.required('')
		.min(3, 'Minimum 3 characters are required'),
	primaryAdmins: yup.array().of(primaryAdminShape),
	location: yup.object().shape(locationShape).required(''),
	entitlements: yup.array().of(yup.string()),
	domain: yup.string().nullable(),
	teamId: yup
		.mixed()
		.test('is-urn', 'Invalid teamId (urn:wildcard:123)', teamId => {
			if (teamId) return /^urn:.+:\d+$/.test(teamId);
			return true; // falsy value
		}),
};

const accountShape = yup.object().shape(accountDetailsShape);

export default accountShape;

export const accountDetailsSchema = yup.object(accountDetailsShape);

export const accountFormDataSchema = yup.object({
	accountDetails: yup.object().shape(accountDetailsShape).required(''),
});
