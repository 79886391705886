import { clearSingleAccount, navigateTo } from '@Actions/accounts';
import { clearSingleAccountEntitlements } from '@Actions/entitlements';
import { clearAdditionalUsers } from '@Actions/users';
import { success } from '@innovyze/stylovyze';
import { getValue } from '@Selectors';
import { STATUS_ENUM } from '@Types/shared.types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function useCallToaster() {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const isUpdate = !!id;

	const {
		accounts: {
			updateStatus: { updateAccountStatus, getAccountAfterUpdateStatus },
		},
		entitlements: {
			updateStatus: { upsertEntitlementsStatus },
		},
		users: {
			updateStatus: { bulkInviteUsersStatus },
		},
	} = useSelector(getValue);

	const calls = useMemo(() => {
		const callsArray = [
			getAccountAfterUpdateStatus,
			updateAccountStatus,
			upsertEntitlementsStatus,
			bulkInviteUsersStatus,
		];
		return callsArray.every(status => status === STATUS_ENUM.SUCCESS);
	}, [
		getAccountAfterUpdateStatus,
		updateAccountStatus,
		upsertEntitlementsStatus,
		bulkInviteUsersStatus,
	]);

	useEffect(() => {
		if (calls === true) {
			dispatch(clearAdditionalUsers());
			dispatch(clearSingleAccount());
			dispatch(clearSingleAccountEntitlements());
			if (isUpdate) {
				dispatch(success('Account updated successfully'));
			}
			dispatch(navigateTo(''));
		}
	}, [calls]);
}
