import { BulkUserRequest, GetUsersParams, User } from '@Types/user.types';

import { AxiosResponse } from 'axios';
import superadmin from '@Apis/superadmin.api';

export const getUsers = (params: GetUsersParams) =>
	superadmin.get('/users', { params });

export const bulkUsersActionService = (
	request: BulkUserRequest,
): Promise<AxiosResponse<{ items: User[] }>> =>
	superadmin.post('/user/bulk', request);
