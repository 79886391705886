import {
	FORM_MODES,
	InputAutocompleteMulti,
	InputText,
	InputAutocomplete,
	getEnvironment,
} from '@innovyze/stylovyze';
import { FormikProps, FormikState } from 'formik';

import { FormHelperText } from '@material-ui/core';
import { FormMode } from '@innovyze/stylovyze/components/StylovyzeForm/types';
import { Geocoder } from '@innovyze/inno-map';
import { InputWrapper } from './AccountDetailsFields.styles';
import { OrgAccount } from '@Types/account.types';
import React from 'react';
import getMandatoryLabel from './hoks/getMandatoryLabel';
import { getValue } from '@Selectors';
import useAccountDetailsLogic from './hooks/useAccountDetailsLogic';
import { useSelector } from 'react-redux';
import useUpdateGeocoderData from './hooks/useUpdateGeocoderData';
import { AccountTypes } from '@innovyze/shared-utils/lib/types/Account';

interface Props {
	formikProps: FormikProps<OrgAccount>;
	formMode: FormMode;
}

export interface FormikUser {
	name?: string;
	email?: string;
}

const AccountDetailsFields = ({ formikProps, formMode }: Props) => {
	const { setFieldTouched, setFieldValue } = formikProps;
	const { touched, errors } = formikProps as FormikState<OrgAccount>;
	const primaryAdminErrors = errors?.primaryAdmins as FormikUser[];
	const primaryAdminTouched = touched?.primaryAdmins as FormikUser[];
	const {
		accounts: { accountById },
	} = useSelector(getValue);

	useAccountDetailsLogic(formikProps);
	const { updateGeocoderData } = useUpdateGeocoderData(formikProps);

	const getFieldTouchEvents = (name: string) => ({
		onBlur: () => {
			setFieldTouched(name, true);
		},
		onFocus: () => {
			setFieldTouched(name, false);
		},
	});

	const valueMappingAccountTypeOptions = Object.values(AccountTypes).map(
		e => ({
			key: e,
			value: e,
		}),
	);

	return (
		<>
			{formMode == FORM_MODES.EDIT && (
				<InputWrapper>
					<InputText
						dataCy="organization-id"
						fieldAttrs={{ name: 'organizationId' }}
						textField={{
							...getFieldTouchEvents('organizationId'),
							label: getMandatoryLabel('Organization ID'),
							disabled: true,
							autoComplete: 'off',
							helperText:
								errors.organizationId && touched.organizationId
									? errors.organizationId
									: '',
						}}
					/>
				</InputWrapper>
			)}
			<InputWrapper>
				<InputText
					dataCy="account-name"
					fieldAttrs={{ name: 'organizationName' }}
					textField={{
						...getFieldTouchEvents('organizationName'),
						label: getMandatoryLabel('Account Name'),
						autoComplete: 'off',
						helperText:
							errors.organizationName && touched.organizationName
								? errors.organizationName
								: '',
					}}
				/>
			</InputWrapper>
			<InputWrapper>
				<InputText
					dataCy="team-id"
					fieldAttrs={{ name: 'teamId' }}
					textField={{
						...getFieldTouchEvents('teamId'),
						label: 'Team Id',
						autoComplete: 'off',
						helperText:
							errors.teamId && touched.teamId
								? errors.teamId
								: '',
					}}
				/>
			</InputWrapper>
			<InputWrapper>
				<InputText
					dataCy="domain"
					fieldAttrs={{ name: 'domain' }}
					textField={{
						...getFieldTouchEvents('domain'),
						label: 'Domain',
						autoComplete: 'off',
						helperText:
							errors.domain && touched.domain
								? errors.domain
								: '',
					}}
				/>
			</InputWrapper>
			<Geocoder
				defaultSearch={accountById?.details?.location.address}
				// approve lib_inno_map PR #13027 to use an Element as label (red star) and uncomment next line
				// label={getMandatoryLabel('Bounding box')}
				label={'Bounding box *'}
				className="boundingBox"
				onSearchChange={updateGeocoderData}
				id={'location.address'}
			/>
			<FormHelperText error={false}>
				{errors?.location?.address && touched.location?.address
					? errors?.location?.address
					: ''}
			</FormHelperText>
			<InputWrapper>
				<InputText
					dataCy="main-admin-name"
					fieldAttrs={{ name: 'primaryAdmins.0.name' }}
					textField={{
						...getFieldTouchEvents('primaryAdmins.0.name'),
						label: 'Main admin full name',
						autoComplete: 'off',
						helperText:
							Array.isArray(primaryAdminErrors) &&
							Array.isArray(primaryAdminTouched) &&
							primaryAdminTouched[0].name &&
							primaryAdminErrors[0].name,
					}}
				/>
			</InputWrapper>
			<InputWrapper>
				<InputText
					dataCy="main-admin-email"
					fieldAttrs={{ name: 'primaryAdmins.0.email' }}
					textField={{
						...getFieldTouchEvents('primaryAdmins.0.email'),
						label: 'Main admin email',
						autoComplete: 'off',
						helperText:
							Array.isArray(primaryAdminErrors) &&
							Array.isArray(primaryAdminTouched) &&
							primaryAdminTouched[0].email &&
							primaryAdminErrors[0].email,
					}}
				/>
			</InputWrapper>
			<InputAutocompleteMulti
				dataCy="additionalAdmins"
				options={[]}
				fieldAttrs={{ name: 'additionalAdmins' }}
				label="Additional admins"
				autocompleteProps={{
					disableClearable: true,
					freeSolo: true,
				}}
			/>
			<InputAutocomplete
				dataCy="accountType"
				fieldAttrs={{
					name: 'accountType',
					defaultValue:
						getEnvironment() == 'PROD'
							? valueMappingAccountTypeOptions[2].value
							: valueMappingAccountTypeOptions[0].value,
				}}
				options={valueMappingAccountTypeOptions}
				onClear={() => {
					setFieldValue('accountType', '');
				}}
				onChange={(_e, newValue) => {
					setFieldValue('accountType', newValue?.key);
				}}
				label={'Account Type'}
			/>
		</>
	);
};

export default AccountDetailsFields;
