import { all } from 'redux-saga/effects';
import accountsSagas from './accounts';
import createAccountsSagas from './createAccount.saga';
import entitlementsSagas from './entitlements';
import usersSagas from './users';
import devToolsSagas from './devTools';

export default function* rootSaga() {
	yield all([
		...accountsSagas,
		...createAccountsSagas,
		...entitlementsSagas,
		...usersSagas,
		...devToolsSagas,
	]);
}
