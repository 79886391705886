import { AccountsStoreState, OrgAccount, STATUS_ENUM } from '@Types';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	clearNavigation,
	clearSingleAccount,
	createAccount,
	createAccountDetails,
	createAccountDetailsRejected,
	createAccountDetailsResolved,
	getAccountAfterUpdate,
	getAccountAfterUpdateRejected,
	getAccountAfterUpdateResolved,
	getAccounts,
	getAccountsRejected,
	getAccountsResolved,
	getSingleAccount,
	getSingleAccountRejected,
	getSingleAccountResolved,
	navigateTo,
	updateAccountDetailsRejected,
	updateAccountSingleUserInfo,
	updateAccountSingleUserInfoResolved,
} from '@Actions/accounts';

export const initialState: AccountsStoreState = {
	initialized: false,
	paginatedAccounts: undefined,
	accountById: { initialized: true },
	error: undefined,
	navigateTo: undefined,
	submitting: false,
	updateStatus: {
		updateAccountStatus: STATUS_ENUM.IDLE,
		createAccountDetailsStatus: STATUS_ENUM.IDLE,
		getAccountAfterUpdateStatus: STATUS_ENUM.IDLE,
	},
};

interface Accounts {
	[x: string]: (
		state: AccountsStoreState,
		action: AnyAction,
	) => AccountsStoreState | undefined;
}

const reducer: Accounts = {
	[getAccounts.toString()]: state => ({
		...state,
		initialized: false,
		accountById: { initialized: true },
	}),
	[getAccountsResolved.toString()]: (state, action) => {
		return {
			...state,
			initialized: true,
			accounts: action.payload.orgAccounts.map((acc: OrgAccount) => ({
				details: acc,
			})),
			paginatedAccounts: action.payload,
		};
	},
	[getAccountsRejected.toString()]: state => ({
		...state,
		initialized: true,
		accounts: [],
		error: 'Cannot retrieve accounts',
	}),
	[getAccountAfterUpdate.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			getAccountAfterUpdateStatus: STATUS_ENUM.PENDING,
		},
	}),
	[getAccountAfterUpdateResolved.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			getAccountAfterUpdateStatus: STATUS_ENUM.SUCCESS,
		},
	}),
	[getAccountAfterUpdateRejected.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			getAccountAfterUpdateStatus: STATUS_ENUM.ERROR,
		},
	}),
	[createAccount.toString()]: state => ({
		...state,
		submitting: true,
	}),
	[createAccountDetails.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			createAccountDetailsStatus: STATUS_ENUM.PENDING,
		},
	}),
	[createAccountDetailsResolved.toString()]: state => ({
		...state,
		submitting: false,
		updateStatus: {
			...state.updateStatus,
			createAccountDetailsStatus: STATUS_ENUM.SUCCESS,
		},
	}),
	[createAccountDetailsRejected.toString()]: state => ({
		...state,
		submitting: false,
		updateStatus: {
			...state.updateStatus,
			createAccountDetailsStatus: STATUS_ENUM.ERROR,
		},
	}),
	[clearSingleAccount.toString()]: state => {
		return {
			...state,
			accountById: { initialized: true },
		};
	},
	[getSingleAccount.toString()]: state => {
		return {
			...state,
			accountById: { initialized: false },
		};
	},
	[getSingleAccountRejected.toString()]: state => {
		return {
			...state,
			accountById: { initialized: true },
		};
	},
	[getSingleAccountResolved.toString()]: (state, action) => {
		return {
			...state,
			accountById: { details: action.payload, initialized: true },
		};
	},
	[navigateTo.toString()]: (state, action) => {
		return {
			...state,
			navigateTo: action.payload,
		};
	},
	[clearNavigation.toString()]: state => {
		return {
			...state,
			navigateTo: undefined,
		};
	},
	[updateAccountSingleUserInfo.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			updateAccountStatus: STATUS_ENUM.PENDING,
		},
	}),
	[updateAccountSingleUserInfoResolved.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			updateAccountStatus: STATUS_ENUM.SUCCESS,
		},
	}),
	[updateAccountDetailsRejected.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			updateAccountStatus: STATUS_ENUM.ERROR,
		},
	}),
};

export default createReducer(initialState, reducer);
