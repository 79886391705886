import { FORM_MODES, StylovyzeForm } from '@innovyze/stylovyze';

import AccountLocationMap from './AccountLocationMap';
import { CustomInfoCard } from '@Pages/UpsertAccount/UpsertAccount.styles';
import { OrgAccount } from '@Types/account.types';
import React from 'react';
import { accountDetailsSchema } from '../../schemas/account';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';

const AccountLocationForm = () => {
	const { accountFormData } = useAccountFormContext();
	return (
		<CustomInfoCard title="Location" fullHeight dataCy="locationCard">
			<StylovyzeForm
				initialValues={accountFormData?.accountDetails as OrgAccount}
				mode={FORM_MODES.EDIT}
				validationSchema={accountDetailsSchema}
				onSubmit={() => {
					return;
				}}>
				{formikProps => {
					return (
						<form onSubmit={e => e.preventDefault()}>
							<AccountLocationMap formikProps={formikProps} />
						</form>
					);
				}}
			</StylovyzeForm>
		</CustomInfoCard>
	);
};

export default AccountLocationForm;
