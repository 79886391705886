import { SubscriptionConfig } from '@Types/entitlement.types';

const getEntitlementsString = (newEntitlements?: SubscriptionConfig[]) => {
	if (newEntitlements?.length) {
		return newEntitlements
			.filter(e => !(e?.deleted || false))
			.map(entitlement => entitlement.product);
	}
	return [];
};

export default getEntitlementsString;
