import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';

export const triggerInspectionTilingForTenant = createAction<string>(
	addNamespace('devTools/triggerInspectionTilingForTenant'),
);

export const triggerInspectionTilingForAllTenants = createAction<void>(
	addNamespace('devTools/triggerInspectionTilingForAllTenants'),
);
