import { Environments, PartialServiceMap } from '@innovyze/stylovyze';

const config: PartialServiceMap = {
	[Environments.LOCAL]: {
		tiles: 'https://tiles-dev.info360-dev.com/dev',
		edgeManager: 'https://saas-edge-manager.info360-dev.com/dev',
		superAdmin: 'https://saas-superadmin.info360-dev.com/dev',
		looker: 'https://saas-looker.info360-dev.com/dev/looker',
	},
};

export default config;
