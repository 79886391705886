import { ApplicationWrapper, innovyzeTheme } from '@innovyze/stylovyze';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { BrightnessHighOutlined, BuildOutlined } from '@material-ui/icons';
import { SuperAdmin } from '@Pages/SuperAdmin';
import { UpsertAccount } from '@Pages/UpsertAccount';
import { DevTools } from '@Pages/DevTools';
import { addRouteNamespace } from '@Utils/actions';
import React from 'react';
import './App.css';
import { useIsDevToolsEnabled } from '@Utils/featureFlags';

function App() {
	const isDevToolsEnabled = useIsDevToolsEnabled();

	const superAdmin = [
		{
			title: 'Super Admin',
			to: addRouteNamespace(''),
			icon: <BrightnessHighOutlined />,
			render: <SuperAdmin />,
			cy: 'super-admin',
		},
		{
			title: 'Super Admin / Create Account',
			to: addRouteNamespace('create-account'),
			render: <UpsertAccount />,
			cy: 'super-admin',
		},
		{
			title: 'Super Admin / Edit Account',
			to: addRouteNamespace('edit-account/:id'),
			render: <UpsertAccount />,
			cy: 'super-admin',
		},
	];

	if (isDevToolsEnabled) {
		superAdmin.push({
			title: 'Dev Tools',
			to: addRouteNamespace('dev-tools'),
			icon: <BuildOutlined />,
			render: <DevTools />,
			cy: 'dev-tools',
		});
	}

	const pages: unknown[] = [];

	const isSuperUser = true;
	if (isSuperUser) {
		pages.push(...superAdmin);
	}

	return (
		<MuiThemeProvider theme={innovyzeTheme}>
			<ApplicationWrapper
				pages={pages}
				showSideNav={isDevToolsEnabled}
				isTenantAware={false}
			/>
		</MuiThemeProvider>
	);
}

export default App;
