import {
	AccountFormContext,
	AccountFormDataType,
} from './contexts/AccountFormContext';
import {
	Button,
	EMPTY,
	FORM_MODES,
	InnovyzeRole,
	LoadingPage,
	PageWrapper,
} from '@innovyze/stylovyze';
import { Buttons, StyledCreateEditAccount } from './UpsertAccount.styles';
import React, { useEffect, useState } from 'react';
import { clearAdditionalUsers, getUsers } from '@Actions/users';
import {
	clearSingleAccount,
	getAccounts,
	getSingleAccount,
} from '@Actions/accounts';
import {
	clearSingleAccountEntitlements,
	getSingleAccountEntitlements,
} from '@Actions/entitlements';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AccountDetailsForm from '@Components/AccountDetailsForm';
import AccountEntitlementsForm from '@Components/AccountEntitlementsForm';
import AccountLocationForm from '@Components/AccountLocationMap/AccountLocationForm';
import EntitlementsCard from '@Components/EntitlementsTable/EntitlementsCard';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { addRouteNamespace } from '@Utils/actions';
import { getValue } from '@Selectors';
import { initialAccountFormData } from '@Types/account.types';
import useAccountSubmit from './hooks/useAccountSubmit';
import useCallToaster from './hooks/useCallToaster';
import useDebouncedAccountValidation from './hooks/useDebouncedAccountValidation';
import useNavigateTo from '../../hooks/useNavigateTo';
import TileSingleTenantForm from '@Components/TileSingleTenantForm';
import { useIsDevToolsEnabled } from '@Utils/featureFlags';

export const UpsertAccount = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const isDevToolsEnabled = useIsDevToolsEnabled();
	const { id } = useParams<{ id: string }>();
	useCallToaster();
	useNavigateTo();

	const {
		accounts: { accountById, submitting },
		entitlements: {
			entitlementsByAccount: {
				productConfigs,
				initialized: entitlementsInitialized,
			},
		},
		users: { users: currentAdditionalAdmins },
	} = useSelector(getValue);

	const [accountFormData, setAccountFormData] = useState<AccountFormDataType>(
		initialAccountFormData,
	);
	const [loadEntitlement, setLoadEntitlement] = useState<
		SubscriptionConfig | undefined
	>(undefined);
	const [isAddingEntitlements, setIsAddingEntitlements] = useState(false);

	const { debounceAccountValidation, isValid } =
		useDebouncedAccountValidation();
	useEffect(() => {
		if (productConfigs?.length) {
			setAccountFormData(_formData => ({
				..._formData,
				entitlements: productConfigs,
			}));
		}
	}, [productConfigs]);
	useEffect(() => {
		debounceAccountValidation(accountFormData);
	}, [accountFormData]);

	useEffect(() => {
		dispatch(clearSingleAccountEntitlements());
		dispatch(clearAdditionalUsers());
		dispatch(clearSingleAccount());
		setAccountFormData(initialAccountFormData);
		dispatch(
			getAccounts({
				limit: 10000,
				offset: 0,
				isAutodeskManagedLibrary: true,
			}),
		);
		if (id) {
			dispatch(getSingleAccount(id));
			dispatch(getSingleAccountEntitlements(id));
			dispatch(
				getUsers({
					organization: id,
					limit: 100,
					offset: 1,
					role: InnovyzeRole.ADMIN,
					sort: 'email:1',
				}),
			);
		}
	}, [id]);

	const handleAccountFormSubmit = useAccountSubmit({
		isValid,
		accountFormData,
		currentAdditionalAdmins,
	});
	return accountById?.initialized && entitlementsInitialized ? (
		<AccountFormContext.Provider
			value={{
				accountFormData,
				setAccountFormData,
				isAddingEntitlements,
				setIsAddingEntitlements,
				loadEntitlement,
				setLoadEntitlement,
			}}>
			<PageWrapper
				title={
					!id
						? 'New account'
						: accountFormData.accountDetails.organizationName ||
						  EMPTY
				}
				applyPadding={true}
				isLoading={false}
				secondary={
					<Buttons>
						<Button
							variant="contained"
							dataCy="cancel-upsert-account-button"
							onClick={() => {
								history.push(addRouteNamespace(''));
							}}>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={handleAccountFormSubmit}
							disabled={!isValid || submitting}
							dataCy={
								id
									? 'save-account-button'
									: 'create-new-account-button'
							}>
							{id ? 'Save' : 'Create'}
						</Button>
					</Buttons>
				}
				secondaryNav={{
					items: [],
				}}>
				<StyledCreateEditAccount>
					<AccountDetailsForm
						formMode={id ? FORM_MODES.EDIT : FORM_MODES.VIEW}
					/>
					<AccountLocationForm />
					<AccountEntitlementsForm />
					<EntitlementsCard />
					{isDevToolsEnabled && <TileSingleTenantForm />}
				</StyledCreateEditAccount>
			</PageWrapper>
		</AccountFormContext.Provider>
	) : (
		<LoadingPage />
	);
};

export default UpsertAccount;
