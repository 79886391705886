import { AccountFormDataType } from '@Pages/UpsertAccount/contexts/AccountFormContext';
import { GeocoderResult } from '@innovyze/inno-map';
import { Location } from './location.d';
import { PaginationInfo, getEnvironment } from '@innovyze/stylovyze';
import { STATUS_ENUM } from './shared.types';
import { User } from './user.types';
import { AccountTypes } from '@innovyze/shared-utils/lib/types/Account';

export interface OrgAccount {
	createdBy?: string;
	organizationName: string;
	primaryAdmins: User[];
	additionalAdmins: string[];
	location: Location;
	lastModifiedDate?: string;
	createDate?: string;
	entitlements?: string[];
	organizationId?: string;
	teamId?: string;
	domain?: string;
	accountType?: AccountTypes;
	isAutodeskManagedLibrary?: boolean;
	allowedLibrariesBySuperAdmin?: string[];
}

export interface PaginatedOrgAccounts {
	orgAccounts: OrgAccount[];
	pagination: PaginationInfo;
}

export interface Account {
	_id?: string;
	details?: OrgAccount;
	initialized?: boolean;
}

export interface UpdateAccountStatus {
	updateAccountStatus: STATUS_ENUM;
	createAccountDetailsStatus: STATUS_ENUM;
	getAccountAfterUpdateStatus: STATUS_ENUM;
}

export interface AccountsStoreState {
	initialized: boolean;
	error?: string;
	accountById?: Account;
	paginatedAccounts?: PaginatedOrgAccounts;
	navigateTo?: string;
	submitting?: boolean;
	updateStatus: UpdateAccountStatus;
}

export const initialAccountFormData: AccountFormDataType = {
	accountDetails: {
		organizationName: '',
		teamId: '',
		primaryAdmins: [],
		additionalAdmins: [],
		entitlements: [],
		location: {
			address: '',
			mapPosition: {
				center: [0, 0],
				zoom: 0,
			},
			mapBounds: [0, 0, 0, 0],
		},
		accountType:
			getEnvironment() == 'PROD'
				? AccountTypes.PRODUCTION
				: AccountTypes.TEST,
	},
	entitlements: [],
	librariesDetails: {
		isAutodeskManagedLibrary: false,
		allowedLibrariesBySuperAdmin: [],
	},
};

export interface GeocoderResultWithMapBounds extends GeocoderResult {
	bbox: number[];
}
