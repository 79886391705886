import { useDispatch } from 'react-redux';
import { triggerInspectionTilingForAllTenants } from '@Actions/devTools';

export function useTriggerInspectionTilingForAllTenants() {
	const dispatch = useDispatch();

	const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
		dispatch(triggerInspectionTilingForAllTenants());
	};

	return handleClick;
}
