import { EMPTY, StylovyzeTableRow } from '@innovyze/stylovyze';
import {
	SUBSCRIPTIONS_LIST,
	SubscriptionConfig,
} from '@Types/entitlement.types';

import Delete from '@material-ui/icons/Delete';
import { Edit } from '@material-ui/icons';
import { EntitlementsStylovyzeTable } from './EntitlementsCard.styles';
/* eslint-disable no-undef */
import Moment from '@date-io/moment';
import React from 'react';
import { TableHeader } from '@Types';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';

const moment = new Moment();
interface EntitlementsTableProps {
	entitlements: SubscriptionConfig[];
	setEntitlements: (entitlements: SubscriptionConfig[]) => void;
}

export default function EntitlementsTable({
	entitlements,
	setEntitlements,
}: EntitlementsTableProps) {
	const { setLoadEntitlement } = useAccountFormContext();

	const loadElement = (index: number) => {
		setLoadEntitlement(entitlements[index]);
	};

	const deleteEntitlementFromList = (index: number) => {
		const newEntitlements = entitlements.map((e, i) => {
			if (i === index)
				return {
					...e,
					deleted: true,
				};
			return e;
		});
		setEntitlements(newEntitlements);
	};

	const rows: StylovyzeTableRow[] = entitlements

		.map((entitlement, index: number) => {
			const unlimited = entitlement.entitlementLimit === 9999;
			const entitlementLimit = unlimited
				? 'Unlimited'
				: entitlement.entitlementLimit;
			return {
				key: entitlement._id,
				cells: [
					SUBSCRIPTIONS_LIST.find(
						el => el.key === entitlement.product,
					)?.value || EMPTY,
					entitlement._id,
					entitlementLimit || EMPTY,
					moment.moment(entitlement.startDate).format('MM/DD/YYYY') ||
						EMPTY,
					moment.moment(entitlement.expiry).format('MM/DD/YYYY') ||
						EMPTY,
				],
				onClick: () => {
					loadElement(index);
				},
				buttonItems: [
					{
						text: 'Edit',
						icon: <Edit />,
						onClick: () => {
							loadElement(index);
						},
						cy: 'edit-entitlement',
					},
					{
						text: 'Remove',
						icon: <Delete />,
						onClick: () => {
							deleteEntitlementFromList(index);
						},
						cy: 'delete-entitlement',
					},
				],
				buttonItemProps: {
					cy: 'entitlement-three-dots',
				},
			};
		})
		.filter((_, i) => entitlements[i].deleted !== true);
	const headers: TableHeader[] = [
		{ key: 'name', cell: 'Name', width: '100px', align: 'left' },
		{
			key: 'subscriptionId',
			cell: 'Subscription ID',
			width: '50px',
			align: 'left',
		},
		{
			key: 'users',
			cell: 'Users',
			width: '25px',
			align: 'center',
			sortable: true,
		},
		{
			key: 'description',
			cell: 'Start Date',
			width: '100px',
			align: 'center',
			sortable: true,
		},
		{
			key: 'weight',
			cell: 'Expire Date',
			width: '100px',
			align: 'center',
		},
	];
	return (
		<EntitlementsStylovyzeTable
			dataCy="entitlementsTable"
			title="Subscriptions"
			headers={headers}
			sort={{
				onRequestSort: (_e, name, order) => {
					console.log(`sort by ${name} - ${order}`);
				},
			}}
			rows={rows}
		/>
	);
}
