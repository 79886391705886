import { InputAutocomplete, InputText } from '@innovyze/stylovyze';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useState } from 'react';
import {
	SubscriptionConfig,
	defaultSubscriptionsMap,
} from '@Types/entitlement.types';

import { FormikProps } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Moment from '@date-io/moment';
import { Subscription } from '@innovyze/shared-utils';
import { TextField } from '@material-ui/core';
import { aYearFromNow } from '../../constants';
import filterEntitlementsList from '../../utils/filterEntitlementsList';
import useAccountEntitlementsLogic from './hooks/useAccountEntitlementsLogic';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';

interface Props {
	formikProps: FormikProps<SubscriptionConfig>;
	setIsEntitlementEditing: (value: React.SetStateAction<boolean>) => void;
	subscriptionsList: {
		key: string;
		value: string;
	}[];
	isEntitlementEditing: boolean;
	setDisabledButton: (value: React.SetStateAction<boolean>) => void;
}

const AccountEntitlementsFields = ({
	formikProps,
	setIsEntitlementEditing,
	subscriptionsList,
	isEntitlementEditing,
	setDisabledButton,
}: Props) => {
	const { setFieldTouched, values, setFieldValue } = formikProps;
	const { accountFormData } = useAccountFormContext();
	const [usersEditable, setUsersEditable] = useState(true);

	const unlimited = values.entitlementLimit === 9999;

	useAccountEntitlementsLogic({
		formikProps,
		setIsEntitlementEditing,
		isEntitlementEditing,
		setDisabledButton,
	});

	const defaultEntitlement: Partial<
		SubscriptionConfig & { usersEditable: boolean; unlimited: boolean }
	> = {
		channelLimit: 1,
		entitlementLimit: 1,
		startDate: new Date().valueOf(),
		expiry: aYearFromNow.valueOf(),
		usersEditable: true,
		unlimited: false,
	};

	const entitlementsOptions = filterEntitlementsList(
		subscriptionsList,
		accountFormData?.entitlements || [],
		isEntitlementEditing,
	);

	return (
		<MuiPickersUtilsProvider utils={Moment}>
			<form onSubmit={e => e.preventDefault()}>
				<InputAutocomplete
					dataCy="entitlementList"
					fieldAttrs={{ name: 'product' }}
					autocompleteProps={{
						disabled: isEntitlementEditing,
					}}
					options={entitlementsOptions}
					onClear={() => {
						setFieldValue('product', '');
					}}
					onChange={(_e, newValue) => {
						const subscription = defaultSubscriptionsMap.get(
							newValue?.key as Subscription,
						);
						setFieldValue(
							'entitlementLimit',
							subscription?.entitlementLimit ||
								defaultEntitlement?.entitlementLimit,
						);
						setFieldValue(
							'startDate',
							subscription?.startDate ||
								defaultEntitlement?.startDate,
						);
						setFieldValue(
							'expiry',
							subscription?.expiry || defaultEntitlement?.expiry,
						);
						setUsersEditable(subscription?.usersEditable !== false);
					}}
					label="Subscription"
				/>
				{!unlimited ? (
					<InputText
						dataCy="number-of-subscriptions"
						fieldAttrs={{
							name: 'entitlementLimit',
						}}
						textField={{
							disabled: !values?.product || !usersEditable,
							type: 'number',
							inputProps: { min: 0 },
							onClick: () => {
								setFieldTouched('numberOfEntitlements', true);
							},
							label: 'Users',
							autoComplete: 'off',
						}}
					/>
				) : (
					<TextField
						data-cy="number-of-subscriptions"
						name="subscriptionsLimit-unlimited"
						disabled
						label="Users"
						variant="outlined"
						margin={'normal'}
						color={'primary'}
						inputProps={{ contentEditable: false }}
						value={'Unlimited'}
						fullWidth
					/>
				)}

				<KeyboardDatePicker
					inputVariant="outlined"
					margin="normal"
					color="primary"
					name="startDate"
					format="MM/DD/yyyy"
					minDate={!isEntitlementEditing ? new Date() : undefined}
					label="Start Date"
					value={values.startDate || null}
					disabled={!values?.product || isEntitlementEditing}
					disableToolbar
					fullWidth
					onChange={(date: MaterialUiPickersDate) => {
						setFieldValue('startDate', date?.valueOf());
					}}
				/>
				<KeyboardDatePicker
					inputVariant="outlined"
					margin="normal"
					color="primary"
					name="expiry"
					format="MM/DD/yyyy"
					disabled={!values?.product}
					minDate={values.startDate || new Date()}
					label="Expiration Date"
					value={values.expiry || null}
					disableToolbar
					fullWidth
					onChange={(date: MaterialUiPickersDate) => {
						setFieldValue('expiry', date?.valueOf());
					}}
				/>
			</form>
		</MuiPickersUtilsProvider>
	);
};

export default AccountEntitlementsFields;
