import {
	clearSingleAccountEntitlements,
	getSingleAccountEntitlements,
	getSingleAccountEntitlementsRejected,
	getSingleAccountEntitlementsResolved,
	upsertEntitlementRejected,
	upsertEntitlementResolved,
	upsertEntitlements,
} from '@Actions/entitlements';

import { AnyAction } from 'redux';
import { EntitlementsStoreState } from '@Types/entitlement.types';
import { STATUS_ENUM } from '@Types/shared.types';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: EntitlementsStoreState = {
	entitlementsByAccount: { initialized: false, productConfigs: [] },
	updateStatus: {
		upsertEntitlementsStatus: STATUS_ENUM.IDLE,
	},
};

interface Entitlements {
	[x: string]: (
		state: EntitlementsStoreState,
		action: AnyAction,
	) => EntitlementsStoreState | undefined;
}

const reducer: Entitlements = {
	[upsertEntitlements.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			upsertEntitlementsStatus: STATUS_ENUM.PENDING,
		},
	}),
	[upsertEntitlementResolved.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			upsertEntitlementsStatus: STATUS_ENUM.SUCCESS,
		},
	}),
	[upsertEntitlementRejected.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			upsertEntitlementsStatus: STATUS_ENUM.ERROR,
		},
	}),
	[clearSingleAccountEntitlements.toString()]: state => {
		return {
			...state,
			entitlementsByAccount: { initialized: true, productConfigs: [] },
		};
	},
	[getSingleAccountEntitlements.toString()]: state => {
		return {
			...state,
			entitlementsByAccount: { productConfigs: [], initialized: false },
		};
	},
	[getSingleAccountEntitlementsResolved.toString()]: (state, action) => {
		return {
			...state,
			entitlementsByAccount: {
				pagination: action.payload.pagination,
				productConfigs: action.payload.subscriptions,
				initialized: true,
			},
		};
	},
	[getSingleAccountEntitlementsRejected.toString()]: state => {
		return state;
	},
};

export default createReducer(initialState, reducer);
