import {
	SUBSCRIPTION_NAMES,
	Subscription,
	UNLIMITED_SUBSCRIPTIONS,
} from '@innovyze/shared-utils';

import { PaginationInfo } from '@innovyze/stylovyze';
import { STATUS_ENUM } from './shared.types';
import { aYearFromNow } from '../constants';

export interface PaginatedSubscriptions {
	pagination: PaginationInfo;
	subscriptions: Subscription[];
}

export interface SubscriptionConfig {
	_id: string;
	organization: string;
	product: string;
	entitlementLimit: number;
	channelLimit: number;
	expiry: number;
	startDate: number;
	deleted?: boolean;
}

export const SUBSCRIPTIONS_LIST: { key: string; value: string }[] = [
	{
		key: Subscription.INSIGHT_T1,
		value: SUBSCRIPTION_NAMES[Subscription.INSIGHT_T1],
	},
	{
		key: Subscription.INSIGHT_T2,
		value: SUBSCRIPTION_NAMES[Subscription.INSIGHT_T2],
	},
	{
		key: Subscription.INSIGHT_T3,
		value: SUBSCRIPTION_NAMES[Subscription.INSIGHT_T3],
	},
	{
		key: Subscription.INSIGHT_CM,
		value: SUBSCRIPTION_NAMES[Subscription.INSIGHT_CM],
	},
	{
		key: Subscription.PLANT_T1,
		value: SUBSCRIPTION_NAMES[Subscription.PLANT_T1],
	},
	{
		key: Subscription.PLANT_T2,
		value: SUBSCRIPTION_NAMES[Subscription.PLANT_T2],
	},
	{
		key: Subscription.PLANT_T3,
		value: SUBSCRIPTION_NAMES[Subscription.PLANT_T3],
	},
	{
		key: Subscription.PLANT_T4,
		value: SUBSCRIPTION_NAMES[Subscription.PLANT_T4],
	},
	{
		key: Subscription.PLANT_CM,
		value: SUBSCRIPTION_NAMES[Subscription.PLANT_CM],
	},
	{
		key: Subscription.ASSET_FULL_ACCESS,
		value: SUBSCRIPTION_NAMES[Subscription.ASSET_FULL_ACCESS],
	},
	{
		key: Subscription.ASSET_VIEWER_ONLY_ACCESS,
		value: SUBSCRIPTION_NAMES[Subscription.ASSET_VIEWER_ONLY_ACCESS],
	},
	{
		key: Subscription.ASSET_INSPECTION_FIELD,
		value: SUBSCRIPTION_NAMES[Subscription.ASSET_INSPECTION_FIELD],
	},
	{
		key: Subscription.WATER_T1,
		value: SUBSCRIPTION_NAMES[Subscription.WATER_T1],
	},
	{
		key: Subscription.WATER_T2,
		value: SUBSCRIPTION_NAMES[Subscription.WATER_T2],
	},
	{
		key: Subscription.WATER_T3,
		value: SUBSCRIPTION_NAMES[Subscription.WATER_T3],
	},
	{
		key: Subscription.ICM_1D,
		value: SUBSCRIPTION_NAMES[Subscription.ICM_1D],
	},
	{
		key: Subscription.ICM_1D_2D,
		value: SUBSCRIPTION_NAMES[Subscription.ICM_1D_2D],
	},
	{
		key: Subscription.INFO360DF,
		value: SUBSCRIPTION_NAMES[Subscription.INFO360DF],
	},
];

export interface UpdateEntitlementsStatus {
	upsertEntitlementsStatus: STATUS_ENUM;
}

export interface EntitlementsStoreState {
	entitlementsByAccount: {
		productConfigs: SubscriptionConfig[];
		pagination?: PaginationInfo;
		initialized: boolean;
	};
	updateStatus: UpdateEntitlementsStatus;
}

export const defaultSubscriptionsMap: Map<
	Subscription,
	Partial<SubscriptionConfig & { usersEditable: boolean }>
> = new Map();
for (const sub of SUBSCRIPTIONS_LIST) {
	let val = {
		entitlementLimit: 1,
		startDate: new Date().valueOf(),
		expiry: aYearFromNow.valueOf(),
		usersEditable: true,
	};
	if (UNLIMITED_SUBSCRIPTIONS.has(sub.key as Subscription)) {
		val = { ...val, usersEditable: false, entitlementLimit: 9999 };
	}
	defaultSubscriptionsMap.set(sub.key as Subscription, val);
}
