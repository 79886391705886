/* eslint-disable prettier/prettier */
import { PaginatedSubscriptions, SubscriptionConfig } from '@Types/entitlement.types';

import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';

export const upsertEntitlements = createAction<SubscriptionConfig[]>(
	addNamespace('entitlements/upsertEntitlements'),
);

export const upsertEntitlementResolved = createAction(
	addNamespace('entitlements/upsertEntitlement/resolved'),
);

export const upsertEntitlementRejected = createAction(
	addNamespace('entitlements/upsertEntitlement/rejected'),
);

export const clearSingleAccountEntitlements = createAction(
	addNamespace('entitlements/clear'),
);

export const getSingleAccountEntitlements = createAction<string>(
	addNamespace('entitlements/getSingleAccountEntitlements'),
);

export const getSingleAccountEntitlementsResolved = createAction<PaginatedSubscriptions>(
	addNamespace('entitlements/getSingleAccountEntitlements/resolved'),
);

export const getSingleAccountEntitlementsRejected = createAction(
	addNamespace('entitlements/getSingleAccountEntitlements/rejected'),
);

export const deleteSingleEntitlement = createAction<SubscriptionConfig>(
	addNamespace('entitlements/deleteEntitlement'),
);

export const deleteSingleEntitlementResolved = createAction(
	addNamespace('entitlements/deleteEntitlement/resolved'),
);

export const deleteSingleEntitlementRejected = createAction(
	addNamespace('entitlements/deleteEntitlement/rejected'),
);

export const deleteEntitlements = createAction<SubscriptionConfig[]>(
	addNamespace('entitlements/deleteEntitlements'),
);

export const deleteEntitlementsResolved = createAction(
	addNamespace('entitlements/deleteEntitlements/resolved'),
);

export const deleteEntitlementsRejected = createAction(
	addNamespace('entitlements/deleteEntitlements/rejected'),
);
