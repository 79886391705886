import * as accountsActions from '@Actions/accounts';
import * as entitlementsActions from '@Actions/entitlements';
import * as usersActions from '@Actions/users';

import { BadRequest, Conflict } from 'http-errors';
import {
	RegisteredApplications,
	error as notificationError,
	success,
} from '@innovyze/stylovyze';
import { all, put, retry, take, takeLatest } from 'redux-saga/effects';
import {
	createAccountDetails,
	createAccountDeviceAssociation,
	createAccountGreengrass,
} from '@Services/accounts';

import { AxiosResponse } from 'axios';
import { OrgAccount } from '@Types/account.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from '@innovyze/shared-utils';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { addRouteNamespace } from '@Utils/actions';
import { applicationNameEntitlementMap } from '@Pages/UpsertAccount/utils/getApplicationFromEntitlement';
import { createBulkInviteUsersRequest } from '@Utils/users';
import { createCompanySettings } from '@Services/companySettings';
import { defaultCompanySettings } from '@Utils/constants';

function* createAccountSagas(
	action: PayloadAction<{
		details: OrgAccount;
		entitlements: SubscriptionConfig[];
		greengrassDeprecated: boolean;
	}>,
) {
	try {
		const { details, greengrassDeprecated } = action.payload;

		const accountDetailsRequest: OrgAccount = {
			location: {
				address: details.location.address,
				mapPosition: details.location.mapPosition,
			},
			organizationName: details.organizationName,
			createDate: details.createDate,
			createdBy: details.createdBy,
			domain: details.domain,
			entitlements: details.entitlements || [],
			lastModifiedDate: details.lastModifiedDate,
			organizationId: details.organizationId,
			primaryAdmins: details.primaryAdmins.map(admin => ({
				email: admin.email,
			})),
			additionalAdmins: [],
			isAutodeskManagedLibrary: details.isAutodeskManagedLibrary,
			allowedLibrariesBySuperAdmin: details.allowedLibrariesBySuperAdmin,
		};
		const accountDetailsResponse: AxiosResponse<OrgAccount> = yield retry(
			3,
			6000,
			createAccountDetails,
			accountDetailsRequest,
		);
		if (accountDetailsResponse.data) {
			yield put(
				entitlementsActions.upsertEntitlements(
					action.payload.entitlements.map(entitlement => ({
						...entitlement,
						organization: accountDetailsResponse.data
							.organizationId as string,
						channelLimit:
							entitlement.channelLimit ||
							entitlement.entitlementLimit,
					})),
				),
			);

			const accountPrimaryEntitlement =
				accountDetailsResponse.data?.entitlements &&
				accountDetailsResponse.data?.entitlements.length
					? accountDetailsResponse.data?.entitlements[0]
					: 'app_onu_main';
			const applicationName =
				(applicationNameEntitlementMap.get(
					(accountPrimaryEntitlement as Subscription) ||
						Subscription.INSIGHT_T1,
				) as RegisteredApplications) ||
				RegisteredApplications.APP_ONU_MAIN;

			if (details.primaryAdmins.length) {
				yield put(
					accountsActions.updateAccountUsersInfo({
						...accountDetailsResponse.data,
						primaryAdmins: details.primaryAdmins.map(admin => ({
							email: admin.email,
							name: admin.name,
						})),
					}),
				);
			}
			const bulkInviteUsersRequest = createBulkInviteUsersRequest(
				accountDetailsResponse.data.organizationId as string,
				details.additionalAdmins,
				applicationName,
			);
			yield put(usersActions.bulkInviteUsers(bulkInviteUsersRequest));
			const acctionsCreateAcc = [
				take(entitlementsActions.upsertEntitlementResolved),
				retry(2, 6000, createCompanySettings, {
					...defaultCompanySettings,
					organization: accountDetailsResponse.data.organizationId,
					organizationName: action.payload.details.organizationName,
					teamId: details.teamId,
					domain: action.payload.details.domain,
					defaultNetworkBoundarySWNELngLat:
						details.location.mapBounds || [],
				}),
			];
			if (!greengrassDeprecated) {
				const orgId = accountDetailsResponse.data.organizationId;
				// eslint-disable-next-line prettier/prettier
				acctionsCreateAcc.push(retry(3, 6000, createAccountGreengrass, orgId as string));
				// eslint-disable-next-line prettier/prettier
				acctionsCreateAcc.push(retry(3, 6000, createAccountDeviceAssociation, orgId as string));
			}
			yield all(acctionsCreateAcc);
			yield put(accountsActions.createAccountDetailsResolved());
			yield put(accountsActions.navigateTo(addRouteNamespace('')));
			yield put(success('Account created successfully'));
		} else {
			yield put(accountsActions.createAccountDetailsRejected());
			yield put(notificationError('Error Creating Account'));
		}
	} catch (error) {
		yield put(accountsActions.createAccountDetailsRejected());
		if (error instanceof Conflict) {
			yield put(
				notificationError(
					'Error: Team Id exists for another organization',
				),
			);
		} else if (error instanceof BadRequest) {
			yield put(notificationError('Error: invalid Team Id'));
		} else {
			yield put(notificationError('Error Creating Account'));
		}
	}
}

function* watchCreateAccount() {
	yield takeLatest(accountsActions.createAccount, createAccountSagas);
}

export default [watchCreateAccount()];
