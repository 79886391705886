import { triggerInspectionTilingForTenant } from '@Actions/devTools';
import { AccountFormDataType } from '@Pages/UpsertAccount/contexts/AccountFormContext';
import React from 'react';

import { useDispatch } from 'react-redux';

export function useTriggerInspectionTilingForTenant(
	accountFormData: AccountFormDataType,
) {
	const dispatch = useDispatch();

	const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
		if (accountFormData != undefined) {
			const tenantId = accountFormData.accountDetails.organizationId;
			if (tenantId != undefined) {
				dispatch(triggerInspectionTilingForTenant(tenantId));
			}
		}
	};

	return handleClick;
}
