import { CompanySettings } from '@innovyze/stylovyze';

export const defaultCompanySettings: CompanySettings = {
	dateFormat: 'DD/MM/YYYY',
	hourCycle12: true,
	timeZoneIANA: 'America/New_York',
	UOM: 'Imperial',
	simulationServiceLevelDefaultPressureHeadAmount: 50,
	simulationServiceLevelDefaultTimeInMinutes: 30,
	defaultNetworkBoundarySWNELngLat: [],
	defaultImportCoordinateSystemAuthority: 'EPSG',
	defaultImportCoordinateSystemCode: '4326',
};

export const DEFAULT_LOOKER_PARENT_FOLDER_ID = '1';
