import {
	bulkInviteUsers,
	bulkInviteUsersRejected,
	bulkInviteUsersResolved,
	clearAdditionalUsers,
	getUsersRejected,
	getUsersResolved,
} from '@Actions/users';

import { AnyAction } from 'redux';
import { STATUS_ENUM } from '@Types/shared.types';
import { UsersStoreState } from '@Types/user.types';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: UsersStoreState = {
	initialized: false,
	users: [],
	pagination: {
		total: 0,
		previous: 0,
		next: 0,
		current: 0,
		pages: 0,
	},
	updateStatus: {
		bulkInviteUsersStatus: STATUS_ENUM.IDLE,
	},
};

interface Users {
	[x: string]: (
		state: UsersStoreState,
		action: AnyAction,
	) => UsersStoreState | undefined;
}

const reducer: Users = {
	[getUsersResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		users: action.payload.users,
		pagination: action.payload.pagination,
	}),
	[getUsersRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
	[clearAdditionalUsers.toString()]: () => {
		return {
			...initialState,
			initialized: true,
		};
	},
	[bulkInviteUsers.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			bulkInviteUsersStatus: STATUS_ENUM.PENDING,
		},
	}),
	[bulkInviteUsersResolved.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			bulkInviteUsersStatus: STATUS_ENUM.SUCCESS,
		},
	}),
	[bulkInviteUsersRejected.toString()]: state => ({
		...state,
		updateStatus: {
			...state.updateStatus,
			bulkInviteUsersStatus: STATUS_ENUM.ERROR,
		},
	}),
};

export default createReducer(initialState, reducer);
