import { InnovyzeRole, RegisteredApplications } from '@innovyze/stylovyze';
import { InviteUserRequest, RemoveUserRequest, User } from '@Types/user.types';

function getUsersToDemote(
	currentAdminsArray: string[],
	newAdminsArray: string[],
): string[] {
	const newAdminsSet = new Set(newAdminsArray);
	const usersToDemote = [];

	for (const email of currentAdminsArray) {
		if (!newAdminsSet.has(email)) {
			usersToDemote.push(email);
		}
	}
	return usersToDemote;
}

function getUsersToPromote(
	currentAdminsArray: string[],
	newAdminsArray: string[],
): string[] {
	const currentAdminsSet = new Set(currentAdminsArray);
	const usersToPromote = [];

	for (const email of newAdminsArray) {
		if (!currentAdminsSet.has(email)) {
			usersToPromote.push(email);
		}
	}
	return usersToPromote;
}

function createBulkInviteUsersRequestHelper(
	bulkInviteUsersRequest: InviteUserRequest[],
	organization: string,
	adminsArray: string[],
	role: InnovyzeRole,
	applicationName: RegisteredApplications,
): void {
	for (const email of adminsArray) {
		bulkInviteUsersRequest.push({
			organization,
			entitlements: [],
			user: {
				email: email,
			},
			role: role,
			applicationName,
		});
	}
}

export function createBulkInviteUsersRequest(
	organization: string,
	additionalAdmins: string[],
	applicationName: RegisteredApplications,
	currentAdditionalAdmins: User[] = [],
): InviteUserRequest[] {
	const currentAdminsArray = currentAdditionalAdmins.map(user => user.email);

	const usersToPromote = getUsersToPromote(
		currentAdminsArray,
		additionalAdmins,
	);

	const bulkInviteUsersRequest: InviteUserRequest[] = [];

	if (usersToPromote.length >= 1) {
		createBulkInviteUsersRequestHelper(
			bulkInviteUsersRequest,
			organization,
			usersToPromote,
			InnovyzeRole.ADMIN,
			applicationName,
		);
	}
	return bulkInviteUsersRequest;
}

export function createBulkRemoveUsersRequest(
	organization: string,
	additionalAdmins: string[],
	currentAdditionalAdmins: User[] = [],
): RemoveUserRequest[] {
	const currentAdminsArray = currentAdditionalAdmins.map(user => user.email);

	const usersToDemote = getUsersToDemote(
		currentAdminsArray,
		additionalAdmins,
	);

	const bulkRemoveUsersRequest: RemoveUserRequest[] = [];

	if (usersToDemote.length >= 1) {
		for (const email of usersToDemote) {
			bulkRemoveUsersRequest.push({
				organization,
				email,
			});
		}
	}
	return bulkRemoveUsersRequest;
}
