import { getApiEnvironment, getService } from '@innovyze/stylovyze';

import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from './config.api';
import cookies from 'browser-cookies';

// Service configuration docs: https://dev.azure.com/innovyze/global/_wiki/wikis/global.wiki/830/2020-10-13-Centralized-Services
const options = {
	baseURL: getService('superAdmin', config, getApiEnvironment()),
	timeout: 6000,
};

const superadmin = axios.create(options);

superadmin.interceptors.request.use(
	conf => {
		conf.headers.Authorization =
			'Bearer ' + cookies.get('auth0.user.token');
		return conf;
	},
	error => {
		Promise.reject(error);
	},
);

axiosRetry(superadmin, {
	retries: 1,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default superadmin;
