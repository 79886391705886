/// <reference types="cypress" />
// @ts-check
/*global Cypress*/

import { Store, applyMiddleware, combineReducers, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { moduleNamespace } from './config.store';
import reducers from '@Reducers';
import rootSaga from '../sagas';
import { reducers as sharedReducers } from '@innovyze/stylovyze';

declare global {
	interface Window {
		store: Store;
		Cypress: Record<string, unknown>;
	}
}

const sagaMiddleware = createSagaMiddleware();

const store: Store = createStore(
	combineReducers({ [moduleNamespace]: reducers, ...sharedReducers }),
	composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
/* istanbul ignore else */
if (window.Cypress) {
	window.store = store;
}

export default store;
