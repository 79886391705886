import { FORM_MODES, StylovyzeForm } from '@innovyze/stylovyze';
import { FormMode } from '@innovyze/stylovyze/components/StylovyzeForm/types';
import React from 'react';

import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';
import { CustomInfoCard } from '@Pages/UpsertAccount/UpsertAccount.styles';
import { accountDetailsSchema } from '../../schemas/account';
import AccountDetailsFields from './AccountDetailsFields';

interface AccountDetailsFormProps {
	formMode: FormMode;
}
const AccountDetailsForm = ({ formMode }: AccountDetailsFormProps) => {
	const { accountFormData } = useAccountFormContext();
	return (
		<CustomInfoCard title="Details" fullHeight dataCy="detailsCard">
			<StylovyzeForm
				initialValues={accountFormData?.accountDetails}
				mode={FORM_MODES.EDIT}
				validationSchema={accountDetailsSchema}
				onSubmit={() => void 0}>
				{formikProps => {
					return (
						<form onSubmit={e => e.preventDefault()}>
							<AccountDetailsFields
								formMode={formMode}
								formikProps={formikProps}
							/>
						</form>
					);
				}}
			</StylovyzeForm>
		</CustomInfoCard>
	);
};

export default AccountDetailsForm;
