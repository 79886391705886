import React from 'react';

const AccountBlankIllustration = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={100}
			height={96}
			viewBox="0 0 100 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M40.0298 68.4336C47.9405 68.4336 55.3228 64.1257 59.2971 57.1911C62.9436 50.8276 62.983 43.3807 59.4026 37.2706C59.3866 37.2435 59.3696 37.2172 59.3516 37.1918L40.8582 11.4248C40.7637 11.2933 40.6394 11.1862 40.4953 11.1123C40.3513 11.0385 40.1917 11 40.0298 11C39.8681 11.0001 39.7087 11.0387 39.5648 11.1125C39.4209 11.1864 39.2966 11.2934 39.2023 11.4248L20.7097 37.1204C20.6907 37.1469 20.6729 37.1743 20.6565 37.2025C17.0703 43.355 17.1175 50.8367 20.7831 57.216C24.7582 64.1346 32.1334 68.4336 40.0298 68.4336ZM22.3933 38.2713L40.0286 13.7665L57.6665 38.3418C60.8976 43.8865 60.8474 50.3842 57.5279 56.1773C53.9165 62.4795 47.2116 66.3945 40.0298 66.3945C32.8613 66.3945 26.1642 62.4882 22.5506 56.2001C19.2123 50.3903 19.1559 43.8583 22.3933 38.2713Z"
				fill="#4A6067"
			/>
			<path
				d="M39.8886 58.815C46.6391 58.815 52.131 53.3498 52.131 46.6316C52.131 46.3612 52.0236 46.1019 51.8324 45.9107C51.6412 45.7195 51.3818 45.6121 51.1114 45.6121C50.841 45.6121 50.5817 45.7195 50.3905 45.9107C50.1993 46.1019 50.0919 46.3612 50.0919 46.6316C50.0919 52.2249 45.5147 56.7759 39.8886 56.7759C39.6182 56.7759 39.3589 56.8833 39.1677 57.0745C38.9765 57.2657 38.8691 57.5251 38.8691 57.7955C38.8691 58.0659 38.9765 58.3252 39.1677 58.5164C39.3589 58.7076 39.6182 58.815 39.8886 58.815Z"
				fill="#4A6067"
			/>
		</svg>
	);
};

export default AccountBlankIllustration;
