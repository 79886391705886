import { AccountFormDataType } from '../contexts/AccountFormContext';
import { Dispatch } from 'redux';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { upsertEntitlements } from '@Actions/entitlements';

interface Attrs {
	accountFormData: AccountFormDataType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: Dispatch<any>;
}

export function updateAccountEntitlements({
	accountFormData,
	dispatch,
}: Attrs) {
	const entitlements: SubscriptionConfig[] = accountFormData.entitlements.map(
		entitlement => ({
			_id: entitlement._id,
			entitlementLimit: entitlement.entitlementLimit,
			expiry: entitlement.expiry,
			product: entitlement.product,
			deleted: entitlement?.deleted || false,
			startDate: entitlement.startDate,
			organization:
				entitlement.organization ||
				accountFormData?.accountDetails?.organizationId ||
				'',
			channelLimit:
				entitlement.channelLimit || entitlement.entitlementLimit,
		}),
	);
	dispatch(upsertEntitlements(entitlements));
}

export default updateAccountEntitlements;
