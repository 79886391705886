import {
	InnovyzeRole,
	RegisteredApplications,
	error as notificationError,
} from '@innovyze/stylovyze';
import {
	bulkInviteUsers,
	bulkInviteUsersResolved,
	bulkRemoveUsers,
	bulkRemoveUsersResolved,
} from '@Actions/users';
import {
	createAccountDetailsResolved,
	getAccountAfterUpdate,
	updateAccountDetails,
	updateAccountDetailsRejected,
	updateAccountSingleUserInfo,
	updateAccountSingleUserInfoResolved,
} from '@Actions/accounts';
import {
	createBulkInviteUsersRequest,
	createBulkRemoveUsersRequest,
} from '@Utils/users';
import {
	deleteEntitlements,
	upsertEntitlementResolved,
} from '@Actions/entitlements';
import { useDispatch, useSelector } from 'react-redux';

import { AccountFormDataType } from '../contexts/AccountFormContext';
import { Subscription } from '@innovyze/shared-utils';
import { User } from '@Types/user.types';
import { applicationNameEntitlementMap } from '../utils/getApplicationFromEntitlement';
import { checkAssetsEntitlements } from '@Utils/checkAssetsEntitlements';
import getEntitlementsString from '../utils/getEntitlementsString';
import { getValue } from '@Selectors';
import updateAccountEntitlements from '../utils/updateAccountEntitlements';
import { useParams } from 'react-router-dom';

export default function useHandleAccountFormEdit({
	accountFormData,
	isValid,
	currentAdditionalAdmins,
}: {
	isValid: boolean;
	accountFormData: AccountFormDataType;
	currentAdditionalAdmins?: User[];
}) {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const { productConfigs: initialEntitlements } =
		useSelector(getValue).entitlements.entitlementsByAccount;
	const handleAccountFormEdit = () => {
		const entitlementsString = getEntitlementsString(
			accountFormData.entitlements,
		);

		if (!checkAssetsEntitlements(entitlementsString)) {
			dispatch(updateAccountDetailsRejected());
			dispatch(
				notificationError(
					'Assets subscriptions must include at least 1 seat of Full Access.',
				),
			);
			return;
		}
		dispatch(getAccountAfterUpdate(id));

		const applicationName =
			applicationNameEntitlementMap.get(
				entitlementsString[0] as Subscription,
			) || RegisteredApplications.APP_ONU_MAIN;
		if (
			accountFormData.accountDetails.primaryAdmins[0] &&
			accountFormData.accountDetails.organizationId
		) {
			accountFormData.accountDetails.primaryAdmins.forEach(admin => {
				dispatch(
					updateAccountSingleUserInfo({
						entitlements: entitlementsString,
						organization: accountFormData.accountDetails
							.organizationId as string,
						user: admin,
						role: InnovyzeRole.ADMIN,
						applicationName,
					}),
				);
			});
		} else {
			dispatch(updateAccountSingleUserInfoResolved());
		}

		if (accountFormData.accountDetails.organizationId) {
			const bulkInviteUsersRequest = createBulkInviteUsersRequest(
				accountFormData.accountDetails.organizationId,
				accountFormData.accountDetails.additionalAdmins,
				applicationName,
				currentAdditionalAdmins,
			);
			dispatch(bulkInviteUsers(bulkInviteUsersRequest));
			const bulkRemoveUsersRequest = createBulkRemoveUsersRequest(
				accountFormData.accountDetails.organizationId,
				accountFormData.accountDetails.additionalAdmins,
				currentAdditionalAdmins,
			);
			dispatch(bulkRemoveUsers(bulkRemoveUsersRequest));
		} else {
			dispatch(bulkInviteUsersResolved());
			dispatch(bulkRemoveUsersResolved());
		}

		if (accountFormData && isValid) {
			dispatch(
				updateAccountDetails({
					...accountFormData.accountDetails,
					entitlements: getEntitlementsString(
						accountFormData.entitlements,
					),
					organizationId: id,
					location: {
						...accountFormData.accountDetails.location,
					},
					isAutodeskManagedLibrary:
						accountFormData.librariesDetails
							.isAutodeskManagedLibrary,
					allowedLibrariesBySuperAdmin:
						accountFormData.librariesDetails
							.allowedLibrariesBySuperAdmin,
				}),
			);
		} else {
			dispatch(createAccountDetailsResolved());
		}

		if (accountFormData?.entitlements?.length) {
			updateAccountEntitlements({ dispatch, accountFormData });
		} else {
			dispatch(upsertEntitlementResolved());
		}

		const entitlementsToDelete = initialEntitlements.filter(entitlement => {
			const entitlementsInUse = accountFormData.entitlements.map(
				_entitlement => _entitlement.product,
			);
			return !entitlementsInUse.includes(entitlement.product);
		});

		dispatch(deleteEntitlements(entitlementsToDelete));
	};

	return handleAccountFormEdit;
}
