import React from 'react';

const getMandatoryLabel = (label: string) => {
	return (
		<>
			{label}
			<strong style={{ color: 'red' }}>{' *'}</strong>
		</>
	);
};

export default getMandatoryLabel;
