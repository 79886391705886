import { OrgAccount, PaginatedOrgAccounts } from '@Types/account.types';

import { AccountTableSearch } from '@Types/search.types';
import { InviteUserRequest } from '@Types/user.types';
import { SubscriptionConfig } from '@Types/entitlement.types';
import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';

export const getAccounts = createAction<AccountTableSearch>(
	addNamespace('accounts/getAccounts'),
);
export const getAccountsResolved = createAction<PaginatedOrgAccounts>(
	addNamespace('accounts/getAccounts/resolved'),
);

export const getAccountsRejected = createAction(
	addNamespace('accounts/getAccounts/rejected'),
);

export const createAccountDetails = createAction<OrgAccount>(
	addNamespace('accounts/createAccountDetails'),
);
export const createAccountDetailsResolved = createAction(
	addNamespace('accounts/createAccountDetails/resolved'),
);

export const createAccount = createAction<{
	details: OrgAccount;
	entitlements: SubscriptionConfig[];
	greengrassDeprecated: boolean;
}>(addNamespace('accounts/createAccount'));

export const createAccountResolved = createAction<OrgAccount>(
	addNamespace('accounts/createAccount/resolved'),
);

export const createAccountRejected = createAction<OrgAccount>(
	addNamespace('accounts/createAccount/rejected'),
);

export const createAccountDetailsRejected = createAction(
	addNamespace('accounts/createAccountDetails/rejected'),
);

export const clearSingleAccount = createAction(addNamespace('accounts/clear'));

export const getSingleAccount = createAction<string>(
	addNamespace('accounts/getAccount'),
);

export const getSingleAccountResolved = createAction<OrgAccount>(
	addNamespace('accounts/getAccount/resolved'),
);

export const getSingleAccountRejected = createAction(
	addNamespace('accounts/getAccount/rejected'),
);

export const navigateTo = createAction<string>(
	addNamespace('navigate/navigateTo'),
);

export const clearNavigation = createAction(addNamespace('navigate/clear'));

export const updateAccountDetails = createAction<OrgAccount>(
	addNamespace('accounts/updateAccountDetails'),
);
export const updateAccountDetailsResolved = createAction(
	addNamespace('accounts/updateAccountDetails/resolved'),
);

export const updateAccountDetailsRejected = createAction(
	addNamespace('accounts/updateAccountDetails/rejected'),
);

export const updateAccountSingleUserInfo = createAction<InviteUserRequest>(
	addNamespace('accounts/updateAccountSingleUserInfo'),
);

export const updateAccountSingleUserInfoResolved = createAction(
	addNamespace('accounts/updateAccountSingleUserInfo/resolved'),
);

export const updateAccountSingleUserInfoRejected = createAction(
	addNamespace('accounts/updateAccountSingleUserInfo/rejected'),
);

export const updateAccountUsersInfo = createAction<OrgAccount>(
	addNamespace('accounts/updateAccountUsersInfo'),
);
export const updateAccountUsersInfoResolved = createAction(
	addNamespace('accounts/updateAccountUsersInfo/resolved'),
);
export const updateAccountUsersInfoRejected = createAction(
	addNamespace('accounts/updateAccountUsersInfo/rejected'),
);

export const getAccountAfterUpdate = createAction<string>(
	addNamespace('accounts/getAccountAfterUpdate'),
);

export const getAccountAfterUpdateResolved = createAction(
	addNamespace('accounts/getAccountAfterUpdate/resolved'),
);

export const getAccountAfterUpdateRejected = createAction(
	addNamespace('accounts/getAccountAfterUpdate/rejected'),
);
