import { InfoCard } from '@innovyze/stylovyze';
import styled from 'styled-components';

export const StyledCreateEditAccount = styled.div`
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(2, 1fr);
	height: 100%;
	position: relative;
	width: 100%;
	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`;

export const CustomInfoCard = styled(InfoCard)`
	min-height: 445px;
`;

export const Buttons = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 16px;
`;

export default StyledCreateEditAccount;
