import * as entitlementActions from '@Actions/entitlements';

import {
	PaginatedSubscriptions,
	SubscriptionConfig,
} from '@Types/entitlement.types';
import {
	deleteSubscription,
	getSingleAccountSubscriptions,
	upsertSubscriptions,
} from '@Services/entitlements';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { error as notificationError } from '@innovyze/stylovyze';

function* upsertEntitlementSaga(action: PayloadAction<SubscriptionConfig[]>) {
	try {
		const response: AxiosResponse<SubscriptionConfig[]> = yield retry(
			3,
			15000,
			upsertSubscriptions,
			action.payload,
		);
		if (response.data) {
			yield put(entitlementActions.upsertEntitlementResolved());
		} else {
			yield put(entitlementActions.upsertEntitlementRejected());
			yield put(notificationError('Error creating entitlements'));
		}
	} catch (error) {
		yield put(entitlementActions.upsertEntitlementRejected());
		yield put(notificationError('Error creating entitlements'));
	}
}

function* deleteSingleEntitlementSaga(
	action: PayloadAction<SubscriptionConfig>,
) {
	try {
		const response: AxiosResponse<SubscriptionConfig[]> = yield retry(
			3,
			6000,
			deleteSubscription,
			action.payload,
		);
		if (response.data) {
			yield put(entitlementActions.deleteSingleEntitlementResolved());
		} else {
			yield put(entitlementActions.deleteSingleEntitlementRejected());
			yield put(notificationError('Error deleting entitlement'));
		}
	} catch (error) {
		yield put(entitlementActions.deleteSingleEntitlementRejected());
		yield put(notificationError('Error deleting entitlement'));
	}
}

function* deleteEntitlementsSaga(action: PayloadAction<SubscriptionConfig[]>) {
	const entitlementsToDelete = action.payload;
	for (const entitlement of entitlementsToDelete) {
		yield put(entitlementActions.deleteSingleEntitlement(entitlement));
	}
	yield put(entitlementActions.deleteEntitlementsResolved());
}

function* getEntitlementsSaga(action: PayloadAction<string>) {
	try {
		const response: AxiosResponse<PaginatedSubscriptions> = yield retry(
			3,
			6000,
			getSingleAccountSubscriptions,
			action.payload,
		);
		if (response.data) {
			yield put(
				entitlementActions.getSingleAccountEntitlementsResolved(
					response.data,
				),
			);
		} else {
			yield put(
				entitlementActions.getSingleAccountEntitlementsRejected(),
			);
			yield put(notificationError('Error getting entitlements'));
		}
	} catch (error) {
		yield put(entitlementActions.getSingleAccountEntitlementsRejected());
		yield put(notificationError('Error getting entitlements'));
	}
}

function* watchDeleteSingleEntitlement() {
	yield takeLatest(
		entitlementActions.deleteSingleEntitlement,
		deleteSingleEntitlementSaga,
	);
}
function* watchDeleteEntitlements() {
	yield takeLatest(
		entitlementActions.deleteEntitlements,
		deleteEntitlementsSaga,
	);
}
function* watchGetEntitlements() {
	yield takeLatest(
		entitlementActions.getSingleAccountEntitlements,
		getEntitlementsSaga,
	);
}

function* watchUpsertEntitlement() {
	yield takeLatest(
		entitlementActions.upsertEntitlements,
		upsertEntitlementSaga,
	);
}

export default [
	watchGetEntitlements(),
	watchUpsertEntitlement(),
	watchDeleteSingleEntitlement(),
	watchDeleteEntitlements(),
];
