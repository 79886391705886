import { RegisteredApplications } from '@innovyze/stylovyze';
import { Subscription } from '@innovyze/shared-utils';

export const applicationNameEntitlementMap = new Map<
	Subscription,
	RegisteredApplications
>();

// app_am_main
// Asset
applicationNameEntitlementMap.set(
	Subscription.ASSET_FULL_ACCESS,
	RegisteredApplications.APP_AM_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.ASSET_INSPECTION_FIELD,
	RegisteredApplications.APP_AM_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.ASSET_VIEWER_ONLY_ACCESS,
	RegisteredApplications.APP_AM_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I360AFA,
	RegisteredApplications.APP_AM_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I360AFI,
	RegisteredApplications.APP_AM_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I360AV,
	RegisteredApplications.APP_AM_MAIN,
);

// app_onu_main
// Insight
applicationNameEntitlementMap.set(
	Subscription.INSIGHT_CM,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.INSIGHT_T1,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.INSIGHT_T2,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.INSIGHT_T3,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.INFO360IU,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I360IU,
	RegisteredApplications.APP_ONU_MAIN,
);

// Plant
applicationNameEntitlementMap.set(
	Subscription.PLANT_CM,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.PLANT_T1,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.PLANT_T2,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.PLANT_T3,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.PLANT_T4,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I36P1CM,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I36P1KM,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I36P50M,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I36P250,
	RegisteredApplications.APP_ONU_MAIN,
);
applicationNameEntitlementMap.set(
	Subscription.I360PU,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.I36P1CM,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.I36P1KM,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.I36P250,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.I36P50M,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.I360PU,
	RegisteredApplications.APP_ONU_MAIN,
);

// Water
applicationNameEntitlementMap.set(
	Subscription.WATER_T1,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.WATER_T2,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.WATER_T3,
	RegisteredApplications.APP_ONU_MAIN,
);

// ICM
applicationNameEntitlementMap.set(
	Subscription.ICM_1D,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.ICM_1D_2D,
	RegisteredApplications.APP_ONU_MAIN,
);

applicationNameEntitlementMap.set(
	Subscription.INFO360DF,
	RegisteredApplications.APP_ONU_MAIN,
);
