import { clearNavigation } from '@Actions/accounts';
import { getValue } from '@Selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function useNavigateTo() {
	const dispatch = useDispatch();
	const {
		accounts: { navigateTo },
	} = useSelector(getValue);
	const history = useHistory();
	useEffect(() => {
		if (history?.location?.pathname !== navigateTo) {
			if (navigateTo !== undefined) {
				history?.push(navigateTo);
			}
		}
		dispatch(clearNavigation());
	}, [navigateTo, history?.location?.pathname]);
}
