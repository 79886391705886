import * as devToolsActions from '@Actions/devTools';
import {
	triggerInspectionTilingForTenant as triggerInspectionTilingForTenantService,
	triggerInspectionTilingForAllTenants as triggerInspectionTilingForAllTenantsService,
} from '@Services/devTools';
import {
	error as notificationError,
	success as notificationSuccess,
} from '@innovyze/stylovyze';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, retry, takeLatest } from 'redux-saga/effects';

function* triggerInspectionTilingForTenant(action: PayloadAction<string>) {
	try {
		const response: AxiosResponse<void> = yield retry(
			3,
			6000,
			triggerInspectionTilingForTenantService,
			action.payload,
		);

		if (response.status >= 200 && response.status < 300) {
			yield put(notificationSuccess('Started inspection tiling'));
		} else {
			yield put(notificationError('Error starting inspection tiling'));
		}
	} catch (error) {
		yield put(notificationError('Error starting inspection tiling'));
	}
}

function* triggerInspectionTilingForAllTenants(action: PayloadAction<void>) {
	try {
		const response: AxiosResponse<void> = yield retry(
			3,
			6000,
			triggerInspectionTilingForAllTenantsService,
		);

		if (response.status >= 200 && response.status < 300) {
			yield put(
				notificationSuccess(
					'Started inspection tiling for all tenants',
				),
			);
		} else {
			yield put(
				notificationError(
					'Error starting inspection tiling for all tenants',
				),
			);
		}
	} catch (error) {
		yield put(
			notificationError(
				'Error starting inspection tiling for all tenants',
			),
		);
	}
}

function* watchTriggerInspectionTilingForTenant() {
	yield takeLatest(
		devToolsActions.triggerInspectionTilingForTenant,
		triggerInspectionTilingForTenant,
	);
}

function* watchTriggerInspectionTilingForAllTenants() {
	yield takeLatest(
		devToolsActions.triggerInspectionTilingForAllTenants,
		triggerInspectionTilingForAllTenants,
	);
}

export default [
	watchTriggerInspectionTilingForTenant(),
	watchTriggerInspectionTilingForAllTenants(),
];
