import {
	AccountFormDataType,
	useAccountFormContext,
} from '@Pages/UpsertAccount/contexts/AccountFormContext';

import { FormikProps } from 'formik';
import { OrgAccount } from '@Types/account.types';

export default function useMapBounds(formikProps: FormikProps<OrgAccount>) {
	const { setAccountFormData } = useAccountFormContext();
	const { setFieldValue, setFieldTouched } = formikProps;

	const updateMapBounds = (mapBounds: number[]) => {
		setAccountFormData((formData: AccountFormDataType) => ({
			...formData,
			accountDetails: {
				...formData.accountDetails,
				location: {
					...formData.accountDetails.location,
					mapBounds,
				},
			},
		}));
		setFieldValue('location.mapBounds', mapBounds);
		setFieldTouched('location', true);
	};

	return { updateMapBounds };
}
