import AccountsTable from '@Components/AccountsTable';
import { success } from '@innovyze/stylovyze';
import { getValue } from '@Selectors';
import { STATUS_ENUM } from '@Types/shared.types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSuperAdminPageWrapper } from './SuperAdmin.styles';

export const SuperAdmin = () => {
	const dispatch = useDispatch();
	const {
		accounts: {
			updateStatus: {
				createAccountDetailsStatus,
				updateAccountStatus,
				getAccountAfterUpdateStatus,
			},
		},
		entitlements: {
			updateStatus: { upsertEntitlementsStatus },
		},
		users: {
			updateStatus: { bulkInviteUsersStatus },
		},
	} = useSelector(getValue);

	const calls = useMemo(() => {
		const callsArray = [
			getAccountAfterUpdateStatus,
			createAccountDetailsStatus,
			updateAccountStatus,
			upsertEntitlementsStatus,
			bulkInviteUsersStatus,
		];
		return callsArray.every(status => status === STATUS_ENUM.SUCCESS);
	}, [
		getAccountAfterUpdateStatus,
		createAccountDetailsStatus,
		updateAccountStatus,
		upsertEntitlementsStatus,
		bulkInviteUsersStatus,
	]);

	useEffect(() => {
		if (calls === true) {
			dispatch(success('Account created or updated successfully'));
		}
	}, [calls]);

	return (
		<StyledSuperAdminPageWrapper
			title={'Info360 Accounts'}
			applyPadding={true}
			isLoading={false}
			secondaryNav={{
				items: [],
			}}>
			<AccountsTable />
		</StyledSuperAdminPageWrapper>
	);
};

export default SuperAdmin;
