import accounts, { initialState as initialAccounts } from './accounts';
import entitlements, {
	initialState as initialEntitlements,
} from './entitlements';
import users, { initialState as initialUsers } from './users';

import { StoreState } from '@Types';
import { combineReducers } from 'redux';
import { moduleNamespace } from '@Store/config.store';

// plop - import reducer here

export const initialState: StoreState = {
	accounts: initialAccounts,
	entitlements: initialEntitlements,
	users: initialUsers,
	// plop - add default reducer state here
};

export default combineReducers({
	accounts,
	entitlements,
	users,
	// plop - add reducer key here
});

export const reducerKey = moduleNamespace;
