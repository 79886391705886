import { isFeatureEnabled } from '@innovyze/stylovyze';

const FLAGS = {
	ggRemoved: 'i-360-release-160025-remove-greengrass-from-tenant-creation',
};

export function useIsDevToolsEnabled(): boolean {
	return isFeatureEnabled('superadmin-enable-dev-tools') ?? false;
}

export function useIsGreengrassCreateDeprecatedEnabled(): boolean {
	return isFeatureEnabled(FLAGS.ggRemoved) ?? false;
}
