import { Subscription } from '@innovyze/shared-utils';

export const checkAssetsEntitlements = (
	entitlementsString: string[],
): boolean => {
	const assetsEntitlements =
		entitlementsString?.filter(
			value =>
				value == Subscription.ASSET_FULL_ACCESS ||
				value == Subscription.ASSET_INSPECTION_FIELD ||
				value == Subscription.ASSET_VIEWER_ONLY_ACCESS,
		) || [];

	const fullAccess = entitlementsString.find(
		assetsEntitlement =>
			assetsEntitlement == Subscription.ASSET_FULL_ACCESS,
	);

	if (assetsEntitlements && assetsEntitlements.length && !fullAccess)
		return false;

	return true;
};

export default checkAssetsEntitlements;
