import {
	GetUsersParams,
	InviteUserRequest,
	PaginatedUsers,
	RemoveUserRequest,
} from '@Types/user.types';

import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';

export const getUsers = createAction<GetUsersParams>(
	addNamespace('users/getUsers'),
);
export const getUsersResolved = createAction<PaginatedUsers>(
	addNamespace('users/getUsers/resolved'),
);
export const getUsersRejected = createAction(
	addNamespace('users/getUsers/rejected'),
);

export const bulkInviteUsers = createAction<InviteUserRequest[]>(
	addNamespace('users/bulkInviteUsers'),
);
export const bulkInviteUsersResolved = createAction(
	addNamespace('users/bulkInviteUsers/resolved'),
);
export const bulkInviteUsersRejected = createAction(
	addNamespace('users/bulkInviteUsers/rejected'),
);
export const bulkRemoveUsers = createAction<RemoveUserRequest[]>(
	addNamespace('users/bulkRemoveUsers'),
);
export const bulkRemoveUsersResolved = createAction(
	addNamespace('users/bulkRemoveUsers/resolved'),
);
export const bulkRemoveUsersRejected = createAction(
	addNamespace('users/bulkRemoveUsers/rejected'),
);
export const clearAdditionalUsers = createAction(addNamespace('users/clear'));
