import { DEBOUNCE_TIME } from '@innovyze/stylovyze';
import { OrgAccount } from '@Types/account.types';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { accountDetailsSchema } from '../../../schemas/account';
import { AccountFormDataType } from '../contexts/AccountFormContext';

interface AccountDetailsState {
	accountDetails: OrgAccount | undefined;
}

export default function useDebouncedAccountValidation() {
	const [isDebouncing, setIsDebouncing] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const setValidatedAccountDetails = ({
		accountDetails,
	}: AccountDetailsState) => {
		accountDetailsSchema
			.validate(accountDetails)
			.then(() => {
				if (accountDetails) {
					setIsValid(true);
				} else {
					setIsValid(false);
				}
				setIsDebouncing(false);
			})
			.catch(() => {
				setIsValid(false);
			});
	};

	const handleDebounceChange = useDebouncedCallback(
		({ accountDetails }: AccountDetailsState) => {
			setValidatedAccountDetails({ accountDetails });
			setIsDebouncing(false);
		},
		DEBOUNCE_TIME / 2,
	);

	const debounceAccountValidation = (
		accountFormData: AccountFormDataType,
	) => {
		const { accountDetails } = accountFormData;
		setIsDebouncing(true);
		handleDebounceChange.callback({ accountDetails });
	};
	return { isDebouncing, debounceAccountValidation, isValid };
}
