import { InfoCard, rem, StylovyzeTable, Text } from '@innovyze/stylovyze';
import styled, { css } from 'styled-components';

const entitlementsCardHeaderMixin = css`
	min-height: 65px;
	padding: 15px 24px;
	font-size: 18px;
`;

export const EntitlementsInfoCard = styled(InfoCard)`
	min-height: 445px;
	.InfoCard--header {
		${entitlementsCardHeaderMixin}
	}
`;

export const EntitlementsStylovyzeTable = styled(StylovyzeTable)`
	.StylovyzeTable--header {
		${entitlementsCardHeaderMixin}
	}
`;

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	align-content: center;
	justify-content: space-around;
	min-height: ${rem(343)};
	margin: auto;
`;

export const ContentWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, auto);
	gap: 8px;
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
	margin: auto;
	vertical-align: middle;
`;

export const IllustrationWrapper = styled.div`
	width: 100px;
`;

export const Name = styled(Text).attrs({
	color: 'secondary',
	variant: 'heading-medium-strong',
	align: 'center',
})``;

export const Description = styled(Text).attrs({
	color: 'secondary',
	variant: 'body2',
	align: 'center',
})``;
