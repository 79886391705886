import { FormikProps, FormikState } from 'formik';

import { OrgAccount } from '@Types/account.types';
import { getValue } from '@Selectors';
import { useAccountFormContext } from '@Pages/UpsertAccount/contexts/AccountFormContext';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useAccountDetailsLogic(
	formikProps: FormikProps<OrgAccount>,
) {
	const { setAccountFormData } = useAccountFormContext();

	const { values } = formikProps as FormikState<OrgAccount>;
	const {
		accounts: { accountById },
		users: { users },
	} = useSelector(getValue);
	const { setValues } = formikProps;

	// Fill formData with the redux state (accountById.details) for every saga that edits It
	useEffect(() => {
		if (accountById) {
			setValues({
				...values,
				...accountById.details,
				additionalAdmins: users.map(user => user.email),
			});
		}
	}, [accountById, users]);

	// Update accountFormData (from context) every time we change the formValues
	useEffect(() => {
		setAccountFormData(_formData => ({
			..._formData,
			accountDetails: values as OrgAccount,
		}));
	}, [values]);
}
