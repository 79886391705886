import { AccountBlankIllustration } from '@Utils/accountImages';
import React from 'react';
import {
	ContentWrapper,
	Description,
	Name,
	Wrapper,
} from './EntitlementsCard.styles';

export const EntitlementsTablePlaceholder = (): React.ReactElement => {
	return (
		<Wrapper>
			<ContentWrapper>
				<AccountBlankIllustration />
				<Name>There are no subscriptions for this account</Name>
				<Description>
					Add a subscription using the form on the left.
				</Description>
			</ContentWrapper>
		</Wrapper>
	);
};

export default EntitlementsTablePlaceholder;
